import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import PaymentHistory, { Payment } from './PaymentHistory';
import {
  GET_PAYMENT_HISTORYVariables,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_account,
  GET_PAYMENT_HISTORY_account_items,
  GET_PAYMENT_HISTORY_account_items_payment_history,
  GET_PAYMENT_HISTORY_account_items_payment_history_items,
} from '../../generated/GET_PAYMENT_HISTORY';
import { formatDate } from '../../util/formatters';

const getPaymentHistoryQuery = loader(
  '../../graphql/query/Get_Payment_History.graphql',
);

interface PaymentHistoryDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export function fetchPaymentHistory(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PAYMENT_HISTORY>> {
  const variables: GET_PAYMENT_HISTORYVariables = {
    account_number: accountNumber,
  } as GET_PAYMENT_HISTORYVariables;
  const queryResult: Promise<ApolloQueryResult<GET_PAYMENT_HISTORY>> =
    awsAppSyncClient.query({
      query: getPaymentHistoryQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_PAYMENT_HISTORY>>;
  return queryResult;
}

export function processPaymentHistory(
  paymentHistory: GET_PAYMENT_HISTORY,
): Payment[] {
  const payments: Payment[] = [];
  const account: GET_PAYMENT_HISTORY_account | null = paymentHistory?.account;
  const accountItems:
    | (GET_PAYMENT_HISTORY_account_items | null)[]
    | null
    | undefined = account?.items;
  accountItems?.forEach(
    (insuredAccount: GET_PAYMENT_HISTORY_account_items | null) => {
      const paymentHistories:
        | (GET_PAYMENT_HISTORY_account_items_payment_history | null)
        | null
        | undefined = insuredAccount?.payment_history;
      const paymentHistoriesItems:
        | (GET_PAYMENT_HISTORY_account_items_payment_history_items | null)[]
        | null
        | undefined = paymentHistories?.items;
      paymentHistoriesItems?.forEach(
        (
          paymentItem: GET_PAYMENT_HISTORY_account_items_payment_history_items | null,
        ) => {
          if (paymentItem) {
            const payment: Payment = {
              date: formatDate(paymentItem.date, '-'),
              method: paymentItem.method ? paymentItem.method : 'NONE',
              status: paymentItem.status ? paymentItem.status : 'NONE',
              failedReason: paymentItem.failed_reason
                ? paymentItem.failed_reason
                : 'NONE',
              paid: paymentItem.amount_paid ? paymentItem.amount_paid : '0',
            } as Payment;
            payments.push(payment);
          }
        },
      );
    },
  );
  return payments;
}

const PaymentHistoryDataContainer = ({
  accountNumber,
  awsAppSyncClient,
}: PaymentHistoryDataContainerProps) => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPaymentHistory(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_PAYMENT_HISTORY>) => {
        const processedPaymentHistory: Payment[] = processPaymentHistory(
          apolloQueryResult.data,
        );
        setPayments(processedPaymentHistory);
      })
      .catch((err: Error) => {
        console.error('GET_PAYMENT_INFO ERROR: ', err);
        setError(err);
      });
    setLoading(false);
  }, [accountNumber]);

  return <PaymentHistory payments={payments} loading={loading} error={error} />;
};

export default PaymentHistoryDataContainer;
