import { SAUXTheme } from '@saux/design-system-react';
import * as saux from '@saux/design-tokens/index';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const ButtonReset = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 50px;
  margin-bottom: 20px;
`;

export const TabActive = styled(ButtonReset)`
  ${(props: any) => {
    const { theme } = props;
    return `
      align-items: center;
      color: ${theme?.color.primary.foreground};
      background-color: ${theme?.color.primary.background};
      border-radius: 53px;
      display: flex;
      font-size: ${theme?.font.primary.size.medium};
      font-weight: ${theme?.font.primary.weight.bold};
      height: 75%;
      justify-content: center;
      padding: 4px ${theme?.size.spacer.large} 4px;
      @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        padding: 8px ${theme?.size.spacer.medium} 8px;
        margin-right: ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

export const TabInactive = styled(ButtonReset)`
  ${(props: any) => {
    const { theme } = props;
    return `
      align-items: center;
      color: ${theme?.color.primary.background};
      background-color: white;
      display: flex;
      font-size: ${theme?.font.primary.size.medium};
      font-weight: ${theme?.font.primary.weight.bold};
      height: 75%;
      justify-content: center;
      padding: 4px ${theme?.size.spacer.large} 4px;
      @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        padding: 8px ${theme?.size.spacer.medium} 8px;
        margin-right: ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

export const DesktopBillingHistoryTable = styled.div`
  #invoice-table-tags {
    height: 34px;
  }
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const MobileBillingHistoryTable = styled.div`
  #invoice-table-tags {
    height: 33px;
  }

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const BillingHistoryTable = styled.div`
  header nav ul li:nth-child(2) {
    margin: 0px 0px 0px 25px;
  }
  article > section > section > ul > li:nth-child(2) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: ${customBase.global.breakpoints.medium -
      1}px) {
      justify-content: flex-end;
    }
    gap: 10px;
  }

  article > section > section > ul > li:nth-child(2) > span {
    margin-right: auto;
  }
`;

export const BillingHistoryContents = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    align-items: center;
    height: 100%;
    width: 518px;
    padding: ${theme.size.spacer.small}px;

    @media screen and (max-width: ${customBase.global.breakpoints.large}px) {
      width: 300px;
    }

    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      justify-content: flex-end;
      padding: 0px 0px 6px 0px;
    }
  `}
`;

export const BillingHistoryRemainingAmount = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-left: ${theme.size.spacer.small}px;
    background-color: ${saux.ColorFillContainerArticle};
    border-radius: 25%;
    padding-bottom: ${theme.size.spacer.xxs}px;
  `}
`;

export const TagWrapper = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => `
    vertical-align: center;
    margin: 0px ${theme.size.spacer.small} 0px 0px;

    @media screen and (max-width: ${customBase.global.breakpoints.large}px) {
      margin: 0px 0px 0px ${theme.size.spacer.small};
    }
`}
`;
