import React, { useState } from 'react';
import InvoiceDetails from '../invoiceDetails';
import { InvoiceDetailsProps } from '../invoiceDetails/DataContainer';
import { InvoiceData } from '../invoiceDetails/InvoiceDetails';

export interface BillingHistorySubMenuProps extends InvoiceDetailsProps {
  downloadInvoiceOnClick: Function;
  closeModalHandler: Function;
  displayModal: boolean;
}

const BillingHistorySubMenu = ({
  invoiceNum,
  dueDate,
  contentTags,
  accountNumber,
  appSyncClient,
  closeModalHandler,
  displayModal,
}: BillingHistorySubMenuProps) => {
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  document.body.style.overflowY = 'auto';

  if (displayModal) {
    document.body.style.overflowY = 'hidden';
    const freshChatIcon = document.getElementById('fc_frame');

    if (freshChatIcon) {
      freshChatIcon.style.zIndex = '0';
    }

    return (
      <InvoiceDetails
        invoiceNum={invoiceNum}
        dueDate={dueDate}
        contentTags={contentTags}
        accountNumber={accountNumber}
        appSyncClient={appSyncClient}
        update={(data: InvoiceData) => setInvoiceDetails(data)}
        invoiceDetails={invoiceDetails}
        closeModalHandler={() => closeModalHandler(false)}
      />
    );
  }

  return <></>;
};

export default BillingHistorySubMenu;
