import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { SAButton, SACard, SAModal, SAText } from '@saux/design-system-react';
import {
  Close,
  Content,
  ModalBody,
  StyledFooter,
  Title,
} from './MakePaymentModalStyles';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import { useFeatureFlag } from '../../util/hooks';

interface MakePaymentModalProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  toggleModalDisplay: Function;
  sourceSystemCode: string;
  isMigrated: boolean;
  baseState: string;
}

const MakePaymentModal = ({
  toggleModalDisplay,
  awsAppSyncClient,
  accountNumber,
  isMigrated,
  baseState,
  // eslint-disable-next-line
  sourceSystemCode,
}: MakePaymentModalProps) => {
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL',
  );
  const feeText = sessionStorage?.userLOB?.includes('Commercial')
    ? '2.5%'
    : '$5';

  const disclaimerTextCT = `I understand that to pay by credit card/debit card I will be transferred to One Inc, a third-party payment processor, to collect my payment information and by clicking “OK” I will be transferred to One Inc. One Inc is a third-party payment solutions provider who will make a payment to State Auto on my behalf.`;

  const renderModalBody = () => (
    <SACard variant="minimal">
      <Title>
        <SAText type="heading-2" weight="bold" text="Disclaimer" />
      </Title>
      <Content>
        {isMigrated ? (
          <SAText
            type="heading-3"
            weight="normal"
            text={baseState === 'Connecticut' ? disclaimerTextCT : `I understand that by clicking 'OK', I will be transferred to One Inc to collect my payment information. One Inc is a third-party payment solutions provider who will make a payment to State Auto on behalf of the insured. Payments made by ACH/eCHECK are not assessed this fee.`}
          />
        ) : (
          <SAText
            type="heading-3"
            weight="normal"
            text={baseState === 'Connecticut' ? disclaimerTextCT : `I understand that by clicking 'OK', I will be transferred to One Inc to collect my payment information and a ${feeText} processing fee will apply to any payments made with my credit or debit card. One Inc is a third-party payment solutions provider who will make a payment to State Auto on behalf of the insured. Payments made by ACH/eCHECK are not assessed this fee.`}
          />
        )}
      </Content>
      <StyledFooter>
        <Close>
          <SAButton
            label="Cancel"
            test-attr="close-one-inc-disclaimer"
            onClick={() => toggleModalDisplay(false)}
            variant="primary-large-outline"
          />
        </Close>
        <Close>
          <SAButton
            label="OK"
            test-attr="go-to-one-inc-from-disclaimer"
            onClick={() => {
              generateOpenOneIncURL(
                awsAppSyncClient,
                accountNumber,
                null,
                SSOneINCURL?.enabled,
                sessionStorage?.userLOB?.includes('Commercial')
                  ? 'GWCL'
                  : 'GWPL',
              );
              simpleGTMDataLayer({
                event: `Overview-MakePaymentButton-click`,
                event_action: 'Button Click',
                event_category: 'Overview',
                event_label: 'Overview Make Payment Button Click',
              });
              simpleGTMDataLayer({
                event: `Snowplow-Overview-MakePaymentButton-click`,
                event_action: 'Snowplow Button Click',
                event_category: 'Snowplow Overview',
                event_label: 'Snowplow Overview Make Payment Button Click',
              });
              toggleModalDisplay(false);
            }}
            variant="primary"
          />
        </Close>
      </StyledFooter>
    </SACard>
  );

  return (
    <SAModal onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}>
      <ModalBody>{renderModalBody()}</ModalBody>
    </SAModal>
  );
};

export default MakePaymentModal;
