/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SATable,
  SATableColumn,
  SAText,
  SAPagination,
  SAPopover,
} from '@saux/design-system-react';
import {
  FailedPaymentToolTip,
  HighlightedText,
  Holder,
  Icon,
  PaymentHistoryPagination,
  PaymentHistoryTable,
  ReasonForFailure,
  ReasonForFailureTitle,
  StatusWrapper,
} from './PaymentHistoryStyles';
import { formatMoney } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useIsResponsive from '../../util/useIsResponsive';

export interface PaymentHistoryProps {
  payments: Payment[];
  loading?: boolean;
  error?: Error;
}

export interface Payment {
  date: string;
  method: string;
  status: string;
  failedReason: string;
  paid: string;
}

enum PaginationNavigation {
  FIRST = 'First',
  NEXT = 'Next',
  PREVIOUS = 'Previous',
  LAST = 'Last',
}

function processDate(date: string): JSX.Element {
  return <SAText type="standard" text={date} colorVariant="default" />;
}

function processMethod(method: string): JSX.Element {
  return <SAText type="standard" text={method} colorVariant="default" />;
}

function processAmountPaid(paid: number): JSX.Element {
  const formattedPaid: string | null = formatMoney(paid) ?? '-';
  const negativePayment: boolean = formattedPaid
    ? formattedPaid.includes('($')
    : false;
  const colorVariant = negativePayment ? 'alert' : 'default';
  return (
    <SAText type="standard" text={formattedPaid} colorVariant={colorVariant} />
  );
}

function organizePaymentInfo(payments: Payment[]): any[][] {
  const rowInfo: any[][] = [];
  payments.forEach((payment: Payment, index: number) => {
    const paymentProcess: any = {
      index,
      processes: [payment.status, payment.failedReason],
    };
    const columnInfo: any[] = [
      payment.date,
      payment.method,
      paymentProcess,
      payment.paid,
    ];
    rowInfo.push(columnInfo);
  });
  return rowInfo;
}

const PaymentHistory = ({ payments, loading, error }: PaymentHistoryProps) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [policyPaginationNav, setPolicyPaginationNav] =
    useState<PaginationNavigation>(PaginationNavigation.FIRST);
  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null,
  );
  const isResponsive = useIsResponsive();

  useEffect(() => {
    if (referenceElement !== null) {
      const statusWrapper = document.querySelector(
        '.payment-history-pagination',
      );
      statusWrapper?.addEventListener(
        'mouseover',
        () => setReferenceElement(null),
        false,
      );
      if (isResponsive) {
        statusWrapper?.addEventListener(
          'click',
          () => setReferenceElement(null),
          false,
        );
      }
    }
  }, [referenceElement]);

  if (error) {
    return <SAText type="standard" text="Error" colorVariant="default" />;
  }
  if (loading) {
    return <SAText type="standard" text="Loading..." colorVariant="default" />;
  }
  if (!payments || payments.length === 0) {
    return (
      <SAText
        type="standard"
        text="No payments exist for your account"
        colorVariant="default"
      />
    );
  }

  function handleClick(event: React.MouseEvent<HTMLElement> | any) {
    event.preventDefault();
    if (referenceElement === null) {
      setReferenceElement(event.currentTarget);
    }
  }

  function processStatus(paymentProcess: any) {
    const paymentStatusIndexInTable: number = paymentProcess.index;
    const paymentStatus: string[] = paymentProcess.processes;
    const status: string = paymentStatus[0];
    const failedReason: string = paymentStatus[1];
    const hasToolTip: boolean = failedReason !== 'NONE';

    return (
      <PaymentHistoryTable>
        {hasToolTip ? (
          <StatusWrapper id="popover-tooltip-status-wrapper">
            <SAPopover
              onClose={() => {}}
              placement={isResponsive ? 'top-start' : 'top'}
              referenceElement={referenceElement}
              variant="popover-custom"
            >
              <FailedPaymentToolTip>
                <Holder>
                  <Icon>
                    <SAIcon
                      size={SAIconSize.medium}
                      icon={SAIcons.info}
                      colorVariant="light"
                    />
                  </Icon>
                  <ReasonForFailureTitle>
                    <SAText
                      type="standard"
                      text="Reason for Payment Failure"
                      weight="bold"
                      colorVariant="highlight-foreground"
                    />
                  </ReasonForFailureTitle>
                </Holder>
                <Holder>
                  <ReasonForFailure>
                    <SAText
                      type="small"
                      text={failedReason}
                      colorVariant="highlight-foreground"
                    />
                  </ReasonForFailure>
                </Holder>
              </FailedPaymentToolTip>
            </SAPopover>
            {isResponsive ? (
              <HighlightedText
                id={`status-text-${paymentStatusIndexInTable}`}
                onClick={(e) => {
                  handleClick(e);
                }}
              >
                <SAText type="standard" text={status} colorVariant="link" />
              </HighlightedText>
            ) : (
              <HighlightedText
                id={`status-text-${paymentStatusIndexInTable}`}
                onMouseOver={(e) => {
                  handleClick(e);
                }}
              >
                <SAText type="standard" text={status} colorVariant="link" />
              </HighlightedText>
            )}
          </StatusWrapper>
        ) : (
          <SAText type="standard" text={status} colorVariant="default" />
        )}
      </PaymentHistoryTable>
    );
  }

  const totalPages = Math.ceil(payments.length / 25);

  let currentPayments: Payment[];
  const paymentHistoryPageChange = (newCurrentPageIndex: number) => {
    if (newCurrentPageIndex === 0) {
      setPolicyPaginationNav(PaginationNavigation.FIRST);
    } else if (totalPages - 1 === newCurrentPageIndex) {
      setPolicyPaginationNav(PaginationNavigation.LAST);
    } else if (selectedPageIndex < newCurrentPageIndex) {
      setPolicyPaginationNav(PaginationNavigation.NEXT);
    } else if (selectedPageIndex > newCurrentPageIndex) {
      setPolicyPaginationNav(PaginationNavigation.PREVIOUS);
    }
    setSelectedPageIndex(newCurrentPageIndex);
  };

  simpleGTMDataLayer({
    event: `BillingPayments-PaymentHistoryPagination${policyPaginationNav}-click`,
    event_action: 'Billing Payments Payment History Pagination Click',
    event_category: `Billing Payments Payment History Pagination ${policyPaginationNav}`,
    event_label: 'Billing Payments Payment History Pagination Click',
  });

  simpleGTMDataLayer({
    event: `Snowplow-BillingPayments-PaymentHistoryPagination${policyPaginationNav}-click`,
    event_action: 'Snowplow Billing Payments Payment History Pagination Click',
    event_category: `Snowplow Billing Payments Payment History Pagination ${policyPaginationNav}`,
    event_label: 'Snowplow Billing Payments Payment History Pagination Click',
  });

  let startIndex: number = 0;
  let lastIndex: number = 25;

  switch (policyPaginationNav) {
    case PaginationNavigation.LAST:
      startIndex = selectedPageIndex * 25;
      lastIndex = selectedPageIndex * 25 + 25;
      currentPayments = payments.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.PREVIOUS:
      startIndex = selectedPageIndex * 25;
      lastIndex = selectedPageIndex * 25 + 25;
      currentPayments = payments.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.NEXT:
      startIndex = selectedPageIndex * 25;
      lastIndex = selectedPageIndex * 25 + 25;
      currentPayments = payments.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.FIRST:
    default:
      currentPayments = payments.slice(startIndex, lastIndex);
      break;
  }
  const columns: SATableColumn[] = [
    {
      align: 'left',
      name: 'Date',
      process: processDate,
    },
    {
      align: 'left',
      name: 'Method',
      process: processMethod,
    },
    {
      align: 'left',
      name: 'Status',
      process: processStatus,
    },
    {
      align: 'left',
      name: 'Amount Paid',
      process: processAmountPaid,
    },
  ];
  const paymentHistoryData: any[][] = organizePaymentInfo(currentPayments);
  return (
    <PaymentHistoryTable className="payment-history-pagination">
      <SATable
        columns={columns}
        data={paymentHistoryData}
        variant="table-to-listview"
      />
      <PaymentHistoryPagination className="payment-history-pagination">
        <div style={{ flexDirection: 'column', right: 0 }}>
          <SAPagination
            currentPageIndex={selectedPageIndex}
            totalItems={payments.length}
            itemsPerPage={25}
            onChange={paymentHistoryPageChange}
          />
        </div>
      </PaymentHistoryPagination>
    </PaymentHistoryTable>
  );
};

PaymentHistory.defaultProps = {
  loading: false,
  error: undefined,
};

export default PaymentHistory;
