import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_BILLING_HISTORY,
  GET_BILLING_HISTORYVariables,
  GET_BILLING_HISTORY_invoices,
  GET_BILLING_HISTORY_invoices_items,
} from '../../generated/GET_BILLING_HISTORY';
import BillingHistory, { Billing } from './BillingHistory';

const getBillingHistoryQuery = loader(
  '../../graphql/query/Get_Billing_History.graphql',
);

interface BillingHistoryDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export function fetchBillingHistory(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_BILLING_HISTORY>> {
  const variables: GET_BILLING_HISTORYVariables = {
    account_number: accountNumber,
  } as GET_BILLING_HISTORYVariables;
  const queryResult: Promise<ApolloQueryResult<GET_BILLING_HISTORY>> =
    awsAppSyncClient.query({
      query: getBillingHistoryQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_BILLING_HISTORY>>;
  return queryResult;
}

export function groupByDate(arr: Billing[]) {
  const pastBillingCollection: any[] = [];
  const futureBillingCollection: any[] = [];
  const today: string = new Date().toISOString().slice(0, 10);

  if (arr && arr.length) {
    arr.sort((a: Billing, b: Billing) => {
      const c: any = new Date(a.dueDate);
      const d: any = new Date(b.dueDate);
      return d - c;
    });

    for (let i = 0; i < arr.length; i += 1) {
      if (
        arr[i] &&
        arr[i].dueDate &&
        (arr[i].dueDate < today || arr[i].dueDate === today)
      )
        pastBillingCollection.push(arr[i]);
      else futureBillingCollection.push(arr[i]);
    }
  }
  return { futureBillingCollection, pastBillingCollection };
}

export function processBillingHistory(
  billingHistory: GET_BILLING_HISTORY,
): Billing[][] {
  const billings: Billing[] = [];
  const invoices: GET_BILLING_HISTORY_invoices | null =
    billingHistory?.invoices;
  const invoicesItems:
    | (GET_BILLING_HISTORY_invoices_items | null)[]
    | null
    | undefined = invoices?.items;
  invoicesItems?.forEach(
    (insuredInvoice: GET_BILLING_HISTORY_invoices_items | null) => {
      if (insuredInvoice) {
        const billing: Billing = {
          dueDate: insuredInvoice.due_date,
          contents: insuredInvoice.contents ? insuredInvoice.contents : [],
          billed: insuredInvoice.invoice_amount,
          paid: insuredInvoice.paid_amount,
          docId: insuredInvoice.docuid,
          invoiceNumber: insuredInvoice.invoice_number,
        };
        billings.push(billing);
      }
    },
  );
  const futureInvoices = groupByDate(billings).futureBillingCollection;
  const pastInvoices = groupByDate(billings).pastBillingCollection;

  return [futureInvoices, pastInvoices];
}

const BillingHistoryDataContainer = ({
  accountNumber,
  awsAppSyncClient,
}: BillingHistoryDataContainerProps) => {
  const [futureBillingInvoices, setFutureBillingInvoices] = useState<Billing[]>(
    [],
  );
  const [pastBillingInvoices, setPastBillingInvoices] = useState<Billing[]>([]);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchBillingHistory(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_BILLING_HISTORY>) => {
        const processedBillingHistory = processBillingHistory(
          apolloQueryResult.data,
        );
        setFutureBillingInvoices(processedBillingHistory[0].reverse());
        setPastBillingInvoices(processedBillingHistory[1]);
      })
      .catch((err: Error) => {
        console.error('GET_PAYMENT_INFO ERROR: ', err);
        setError(err);
      });
    setLoading(false);
  }, [accountNumber]);

  return (
    <BillingHistory
      pastBillingInvoices={pastBillingInvoices}
      futureBillingInvoices={futureBillingInvoices}
      loading={loading}
      error={error}
      accountNumber={accountNumber}
      client={awsAppSyncClient}
    />
  );
};

export default BillingHistoryDataContainer;
