import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PaymentHistoryTable = styled.div``;

export const FailedPaymentToolTip = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    background-color: ${theme.color.dark.background};
    color: ${theme.color.dark.foreground};
    border-radius: 4px;
    box-shadow: ${theme.shadow.elevation.level1};
    padding: ${theme.size.spacer.small};
    padding-top: 0px;
  `}
`;

export const ReasonForFailureTitle = styled.span`
  margin: 0px 0px 0px 10px;
`;

export const ReasonForFailure = styled.span`
  margin: 0px 0px 0px 43px;
  text-overflow: ellipsis;
`;

export const PaymentHistoryPagination = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: ${theme.size.spacer.medium};
    justify-content: flex-end;
    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      justify-content: center;
    }
  `}
`;

export const Holder = styled.div`
  flex-direction: row;
`;

export const StatusWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const HighlightedText = styled.span``;

export const Icon = styled.span`
  position: relative;
  top: 8px;
`;
