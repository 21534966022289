import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import PayPlans, { PayPlanInfo } from './PayPlans';
import {
  GET_PAYPLAN_INFO,
  GET_PAYPLAN_INFOVariables,
  GET_PAYPLAN_INFO_account_account_items_payplans_items,
} from '../../generated/GET_PAYPLAN_INFO';

const getPayPlanInfoQuery = loader(
  '../../graphql/query/Get_PayPlan_Info.graphql',
);

interface PayPlansDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export function fetchPayPlanInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PAYPLAN_INFO>> {
  const variables: GET_PAYPLAN_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYPLAN_INFOVariables;

  const queryResult: Promise<ApolloQueryResult<GET_PAYPLAN_INFO>> =
    awsAppSyncClient.query({
      query: getPayPlanInfoQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_PAYPLAN_INFO>>;
  return queryResult;
}

export function checkIfCurrentDated(combinedPayPlans: any[]) {
  const today: number = new Date().getTime();
  for (let i = 0; i < combinedPayPlans.length; i += 1) {
    if (combinedPayPlans[i].effective_date) {
      if (new Date(combinedPayPlans[i].effective_date).getTime() < today) {
        return true;
      }
    }
  }
  return false;
}

export const findDuplicatePolicyNumber = (
  arr: (GET_PAYPLAN_INFO_account_account_items_payplans_items | null)[],
) => {
  let duplicatePolicyNumber: string = '';
  const counts: {
    [key: string]: number;
  } = {};

  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i]?.policy_number ?? '';
    counts[item] = counts[item] >= 1 ? counts[item] + 1 : 1;
    if (counts[item] > 1) {
      duplicatePolicyNumber = item;
    }
  }

  return duplicatePolicyNumber;
};

export const getAllInstances = (
  arr: (GET_PAYPLAN_INFO_account_account_items_payplans_items | null)[],
  val: string,
) => {
  const instanceCollection = [];

  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i]?.policy_number === val) instanceCollection.push(arr[i]);
  }
  return instanceCollection;
};

export function processPayPlanInfo(
  payPlanInfo: GET_PAYPLAN_INFO,
): PayPlanInfo[] {
  let defaultPaymentMethod: string | null | undefined = '-';
  let policyNumber: string | null | undefined = '-';
  let payPlan: string | null | undefined = '-';
  let policyType: string | null | undefined = '-';
  let policyEffectiveDate: string | null | undefined = '-';
  let amountPaid: number | string | null | undefined = '0';
  let policyPeriodID: string | null | undefined = '-';
  let userID: string | null | undefined = '-';

  const payPlanInfoAccountItems = payPlanInfo?.account?.items;
  let processedPayPlans: any[] = [];
  if (payPlanInfoAccountItems && payPlanInfoAccountItems.length) {
    let payPlanInfoArr = payPlanInfoAccountItems[0]?.payplans?.items;
    const billingSummaryArr =
      payPlanInfo.account?.items[0].billing_summary?.items;
    userID = payPlanInfo?.me?.user_id;

    if (payPlanInfoArr && billingSummaryArr && payPlanInfoArr.length > 0) {
      const streamlinedPayPlansCollection: any[] = [];
      let currentTermPayplans: any[] = [];

      const uniquePayPlanPolicyNumberArray = new Set(
        payPlanInfoArr.map((plan) => plan?.policy_number),
      );
      if (
        payPlanInfoArr &&
        payPlanInfoArr.length &&
        uniquePayPlanPolicyNumberArray.size < payPlanInfoArr.length
      ) {
        const findDuplicateNumber = findDuplicatePolicyNumber(payPlanInfoArr);
        const foundDuplicateObjects = getAllInstances(
          payPlanInfoArr,
          findDuplicateNumber,
        );
        if (foundDuplicateObjects && foundDuplicateObjects.length) {
          let periodID: number = 0;
          let returnedPolicy: GET_PAYPLAN_INFO_account_account_items_payplans_items | null =
            foundDuplicateObjects[0];
          for (let y = 0; y < foundDuplicateObjects.length; y += 1) {
            if (foundDuplicateObjects[y]?.policy_period_id) {
              const objPeriodID = Number(
                foundDuplicateObjects[y]?.policy_period_id?.split('-')[1],
              );
              if (objPeriodID > periodID) {
                periodID = objPeriodID;
                returnedPolicy = foundDuplicateObjects[y];
              }
            }
          }
          const filtered = payPlanInfoArr.filter((plan) => {
            return (
              plan?.policy_number !==
              Array.from(uniquePayPlanPolicyNumberArray)[0]
            );
          });
          filtered.push(returnedPolicy);
          payPlanInfoArr = filtered;
        }
      }

      if (checkIfCurrentDated(billingSummaryArr)) {
        currentTermPayplans = billingSummaryArr.filter((summary) => {
          return (
            summary?.effective_date &&
            summary?.effective_date < new Date().toISOString().slice(0, 10)
          );
        });
      } else {
        currentTermPayplans = billingSummaryArr.filter((summary) => {
          return (
            summary?.effective_date &&
            summary?.effective_date > new Date().toISOString().slice(0, 10)
          );
        });
      }

      currentTermPayplans.forEach((summary) => {
        const matchedByPolicy = payPlanInfoArr?.find((plan) =>
          summary?.policy_number?.includes(plan?.policy_number as string),
        );

        if (matchedByPolicy?.policy?.status?.toLowerCase() !== 'cancelled') {
          const matchedByID = payPlanInfoArr?.find(
            (plan) =>
              summary?.policy_period_id === (plan?.policy_period_id as string),
          );
          let remainingBalance: number = 0;
          if (summary && summary.paid && summary.total_value) {
            remainingBalance =
              parseFloat(summary?.total_value) - parseFloat(summary?.paid);
          }
          defaultPaymentMethod = matchedByPolicy?.default_payment_method;
          policyNumber = summary?.policy_number?.substring(
            0,
            summary?.policy_number?.length - 2,
          );
          payPlan = matchedByPolicy?.pay_plan;
          policyType = matchedByPolicy?.policy?.policy_type;
          policyEffectiveDate = matchedByID?.policy?.effective_date;
          amountPaid = summary?.paid;
          policyPeriodID = summary?.policy_period_id;

          streamlinedPayPlansCollection.push({
            defaultPaymentMethod,
            policyNumber,
            payPlan,
            policyType,
            policyEffectiveDate,
            amountPaid,
            remainingBalance,
            userID,
            policyPeriodID,
          });
        }
      });

      processedPayPlans = streamlinedPayPlansCollection;
    }
  }
  return processedPayPlans;
}

export const formatPayPlanDataForTable = (payPlanInfo: any) => {
  const payPlanArr: string[][] = [];
  if (payPlanInfo) {
    for (let i = 0; i <= payPlanInfo.length - 1; i += 1) {
      payPlanArr.push([
        `${payPlanInfo[i].policyType}`,
        `${payPlanInfo[i].policyNumber}`,
        `${payPlanInfo[i].payPlan}`,
        `${payPlanInfo[i].amountPaid}`,
        `${payPlanInfo[i].remainingBalance}`,
      ]);
    }
  }
  return payPlanArr;
};

const PayPlansDataContainer = ({
  accountNumber,
  awsAppSyncClient,
}: PayPlansDataContainerProps) => {
  const [payPlanInfo, setPayPlanInfo] = useState<PayPlanInfo[]>();
  const [payPlanTableData, setPayPlanTableData] = useState<
    PayPlanInfo[][] | any
  >([]);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPayPlanInfo(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_PAYPLAN_INFO>) => {
        const processedPayPlanInfo: PayPlanInfo[] = processPayPlanInfo(
          apolloQueryResult.data,
        );
        setPayPlanInfo(processedPayPlanInfo);

        setLoading(false);
      })
      .catch((err: Error) => {
        console.error('GET_PAYPLAN_INFO ERROR: ', err);
        setError(err);
        setLoading(false);
      });
  }, [accountNumber]);

  useEffect(() => {
    if (payPlanInfo) {
      setPayPlanTableData(formatPayPlanDataForTable(payPlanInfo));
    }
  }, [payPlanInfo]);

  return (
    <>
      <PayPlans
        accountNumber={accountNumber}
        payPlanInfo={payPlanInfo}
        payPlanTableInfo={payPlanTableData}
        loading={loading}
        error={error}
        awsAppSyncClient={awsAppSyncClient}
      />
    </>
  );
};

export default PayPlansDataContainer;
