import { SAText, SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const BillingHistoryMenu = styled.div`
  position: relative;
`;

export const BillingHistoryKebab = styled.div`
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const BillingHistorySubMenuWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    background: ${theme.color.container.background};
    padding: 16px;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 1;
    box-shadow: ${theme.shadow.elevation.level1};
    border-radius: 5%;

    *:hover {
      cursor: pointer;
    }
  
  `}
`;

export const SubMenuLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 170px;

  div:nth-child(2) {
    margin-top: 15px;
  }
`;

export const SubMenuLink = styled(SAText)`
  ${({ theme }: { theme: SAUXTheme }) => `
    color: ${theme.color.container.foreground};
    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      display: none;
    }
  `}
`;

export const BillingHistoryLink = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  margin-right: -${theme.size.spacer.large};
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`}
`;
