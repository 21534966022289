import { SAUXTheme } from '@saux/design-system-react';
import styled, { keyframes } from 'styled-components';
import customBase from '../../util/global.theme';

export const DateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  height: 225px;
`;

export const SelectableDate = styled.button`
  border: none;
  max-height: 50px;
  max-width: 50px;
  height: 100%;
  width: 100%;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  color: #3b3b3b;
  &:hover {
    cursor: pointer;
  }
`;

export const CurrentDueDate = styled.button`
  border-radius: 50%;
  border: none;
  max-height: 50px;
  max-width: 50px;
  height: 100%;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: #3b3b3b;
  background-color: #d9dfeb;
`;

export const NewDueDate = styled.button`
  border-radius: 50%;
  border: none;
  max-height: 50px;
  max-width: 50px;
  height: 100%;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: white;
  background-color: #0173c6;
`;

export const NotSelectableDate = styled.button`
  border: none;
  max-height: 50px;
  max-width: 50px;
  height: 100%;
  width: 100%;
  background: transparent;
  font-size: 16px;
  color: #3b3b3b;
  opacity: 0.5;
`;

export const Header = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    padding: ${theme.size.spacer.small} 0px ${theme.size.spacer.small} ${theme.size.spacer.large};
  `}
`;

export const IconHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    padding: ${theme.size.spacer.xs} 7px 0px 7px;
  `}
`;

export const HeaderParagraph = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    padding: 0px ${theme.size.spacer.large} ${theme.size.spacer.small} ${theme.size.spacer.large};
    text-align: left;
  `}
`;

export const CalenderHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  padding: 0px ${theme.size.spacer.large} ${theme.size.spacer.small} ${
    theme.size.spacer.large
  };
  @media screen and (max-width: ${customBase.global.breakpoints.xsmall + 1}px) {
    padding: 0px ${theme.size.spacer.small} ${theme.size.spacer.small} ${
    theme.size.spacer.small
  };
  }
`}
`;

export const Footer = styled.div`
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
  display: flex;
  flex-direction: row;
  margin: auto 0px 0px auto;
`;

export const ResponsiveFooter = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      display: none;
    }
    display: flex;
    flex-direction: row;
    margin: auto ${theme.size.spacer.large} ${theme.size.spacer.large} ${theme.size.spacer.large};
  `}
`;

export const ButtonHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: 0px ${theme.size.spacer.large} ${theme.size.spacer.large} 0px;
  `}
`;

// z-index of 2147483601 is one unit above the freshchat icon
export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      z-index: 2147483601;
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
          width: 460px;
          height: 690px;
      }
      padding-top: 25px;
      height: -moz-available;          
      height: -webkit-fill-available;
      height: fill-available;
      width: -moz-available;          
      width: -webkit-fill-available;
      width: fill-available;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      `;
  }}
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(4, 30, 65, 0.6);
  z-index: 2147483601;
`;

export const MessageInnerText = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: inline-block;
    text-align: left;
    padding: 8px 6px ${theme.size.spacer.small} 2px;
  `}
`;

export const FullSizeBlueMessage = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    margin: ${theme.size.spacer.medium} ${theme.size.spacer.large} 20px ${theme.size.spacer.large};
    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      width: 90%;
    }
    width: 400px;
    height: auto;
    border-radius: ${theme.size.radius.small};
    border: 1px solid #189ecc;
    background-color: #e2f5f9;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  `}
`;

export const FullSizeYellowMessage = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    margin: ${theme.size.spacer.medium} ${theme.size.spacer.large} 20px ${theme.size.spacer.large};
    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      width: 90%;
    }
    width: 400px;
    height: auto;
    border-radius: ${theme.size.radius.small};
    border: 1px solid #F5A524;
    background-color: #FFF6E9;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
  `}
`;

export const DesktopHeader = styled.div`
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const MobileHeader = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
        display: none;
      } 
      margin-bottom: ${theme?.size?.spacer?.medium};
    `;
  }}
`;

export const HeaderHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const CloseIcon = styled.div`
  margin: 7.5px 0px 7.5px 0px;
`;

export const MobileWordingRow = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    background-color: #e8ebf2;
    height: 2px;
    margin-top: ${theme.size.spacer.medium};
    @media (min-width: ${customBase.global.breakpoints.medium}px) {
      display: none;
    }
  `}
`;

export const closing = keyframes`
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 30px;
    opacity: 0;
  }
`;

export const persisting = keyframes`
from {
  bottom: 30px;
  opacity: 1;
}
to {
  bottom: 30px;
  opacity: 1;
}
`;

// z-index of 2147483601 is one unit above the freshchat icon
export const SuccessTooltip = styled.div`
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    width: auto;
    left: 8px;
    right: 8px;
  }
  z-index: 2147483601;
  width: 370px;
  height: 77px;
  border-radius: 7px;
  border-left: 6px solid #65a100;
  background-color: #f2f8e6;
  padding: 12px 27px 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  top: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  position: fixed;
`;

// z-index of 2147483601 is one unit above the freshchat icon
export const FailureTooltip = styled.div`
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    width: auto;
    left: 8px;
    right: 8px;
  }
  z-index: 2147483601;
  width: 370px;
  height: 77px;
  border-radius: 7px;
  border-left: 6px solid #b00020;
  background-color: #ffcccb;
  padding: 12px 27px 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  top: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  position: fixed;
`;

export const FinalMessageInnerText = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: inline-block;
    text-align: left;
    padding: 8px 12px ${theme.size.spacer.small} 12px;
  `}
`;

export const CheckSizing = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: 20px;
      padding-right: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const CloseSizing = styled.div`
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;
