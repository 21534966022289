import React, { MutableRefObject } from 'react';
import { SASnackbar } from '@saux/design-system-react';
import useIsResponsive from '../../util/useIsResponsive';
import { ReactComponent as InfoQuestionMark } from '../../assets/images/InfoQuestionMark.svg';
import { ResponsiveToolTipIconWrapper } from '../nextPayment/NextPaymentStyles';
import { ToolTip } from './DesktopTooltip';

interface ResponsiveTooltipProps {
  toolTip: MutableRefObject<ToolTip>;
  setShowToolTip: Function;
}

interface ResponsiveTooltipIconProps {
  setShowToolTip: Function;
}

export const ResponsiveTooltipIcon = ({
  setShowToolTip,
}: ResponsiveTooltipIconProps) => (
  <ResponsiveToolTipIconWrapper>
    <InfoQuestionMark onClick={() => setShowToolTip(true)} />
  </ResponsiveToolTipIconWrapper>
);

const ResponsiveTooltip = ({
  toolTip,
  setShowToolTip,
}: ResponsiveTooltipProps) => {
  const isResponsive = useIsResponsive();
  if (isResponsive) {
    return (
      <SASnackbar
        test-attr="responsive-tool-tip"
        open
        title={toolTip.current.title}
        subtitle={toolTip.current.subtitle}
        icon="information"
        zIndex={1}
        offset={[0, 85]}
        variant="snackbar-info"
        position="bottom-start"
        autoHideDuration={5000}
        onClose={() => setShowToolTip(false)}
      />
    );
  }

  return <></>;
};

export default ResponsiveTooltip;
