import React, { useState } from 'react';
import { SACard, SATable, SAText, SATextType } from '@saux/design-system-react';
import AWSAppSyncClient from 'aws-appsync';
import {
  AltBankAccount,
  BankAccount,
  Container,
  GrayBox,
  Heading,
  LeftSide,
  RightSide,
  RightSideHeading,
  SideBySide,
  Spacer,
} from './PayPlansStyles';
import { formatMoney } from '../../util/formatters';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import { GET_PAYPLAN_INFO } from '../../generated/GET_PAYPLAN_INFO';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import OutageModal from '../outageModal/OutageModal';

export interface PayPlansProps {
  accountNumber: string;
  payPlanInfo?: PayPlanInfo[];
  payPlanTableInfo?: PayPlanInfo[][] | any;
  loading?: boolean;
  error?: Error;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export interface PayPlanInfo {
  amountPaid: number | string | null | undefined;
  defaultPaymentMethod: string | null | undefined;
  payPlan: string | null | undefined;
  policyNumber: string | null | undefined;
  policyType: string | undefined;
  policyEffectiveDate: string | undefined;
  remainingBalance: number | string | null | undefined;
  userID: string | null | undefined;
  policyPeriodID: string | null | undefined;
}

function processColumn2(value: string) {
  const colorVariant = 'link';

  return (
    <div className="policy-link">
      <SAText
        type="standard"
        text={`...${value.substring(value.length - 4, value.length)}`}
        colorVariant={colorVariant}
        onClick={() => {
          sessionStorage.setItem('policyNumber', value);
          window.open('/policy', '_self');

          simpleGTMDataLayer({
            event: 'BillingPayments-PolicyNumRedirect-click',
            event_action: 'Billing Payments Policy Num Redirect Click',
            event_category: 'Billing Payments Policy Num Redirect',
            event_label: 'Billing Payments Policy Num Redirect Click',
          });

          simpleGTMDataLayer({
            event: 'Snowplow-BillingPayments-PolicyNumRedirect-click',
            event_action: 'Snowplow Billing Payments Policy Num Redirect Click',
            event_category: 'Snowplow Billing Payments Policy Num Redirect',
            event_label: 'Snowplow Billing Payments Policy Num Redirect Click',
          });
        }}
      />
    </div>
  );
}

function processColumn4and5(value: number) {
  const text = formatMoney(value);
  return <SAText type="standard" text={text !== null ? text : undefined} />;
}

export const editPaymentInfoClick = (
  payPlanInfo: GET_PAYPLAN_INFO | any,
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
  SSOneINCURL?: boolean | null,
) => {
  let userId: string = '';
  if (payPlanInfo.length) {
    userId = payPlanInfo[0].userID ? payPlanInfo[0].userID : '';
  }
  generateOpenOneIncURL(
    awsAppSyncClient,
    accountNumber,
    userId,
    SSOneINCURL,
    sessionStorage?.userLOB?.includes('Commercial') ? 'GWCL' : 'GWPL',
  );
};

const columns: any[] = [
  { align: 'left', name: 'Policy Type' },
  { name: 'Policy Number', align: 'center', process: processColumn2 },
  { name: 'Payment Plan', align: 'center' },
  { name: 'Amount Paid', align: 'center', process: processColumn4and5 },
  { align: 'center', name: 'Remaining Balance', process: processColumn4and5 },
];

const PayPlans = ({
  accountNumber,
  loading,
  error,
  payPlanInfo,
  payPlanTableInfo,
  awsAppSyncClient,
}: PayPlansProps) => {
  const [showOutageModal, setShowOutageModal] = useState<boolean>(false);
  const { flagDetails: BillingCenterOutage } = useFeatureFlag(
    'BillingCenterOutage',
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL',
  );

  if (loading) {
    return (
      <SACard
        variant="standard"
        title="Pay Plans"
        actionLabel="EDIT PAYMENT INFO"
        actionLabelClickHandler={() => {}}
      >
        <Container numberOfPlans={0}>
          <SAText type="standard" text="Loading..." />
        </Container>
      </SACard>
    );
  }

  if (error) {
    return (
      <SACard
        variant="standard"
        title="Pay Plans"
        actionLabel="EDIT PAYMENT INFO"
        actionLabelClickHandler={() => {}}
      >
        <Container numberOfPlans={0}>
          <SAText type="standard" text="Error" />
        </Container>
      </SACard>
    );
  }

  const paymentMethod: string =
    payPlanInfo &&
    payPlanInfo[0]?.defaultPaymentMethod &&
    payPlanInfo[0]?.defaultPaymentMethod?.toLowerCase() !== 'responsive'
      ? payPlanInfo[0]?.defaultPaymentMethod
      : 'No Default Payment Method';

  const editPaymentInfoClickHandler = () => {
    if (BillingCenterOutage?.enabled) {
      setShowOutageModal(true);
    } else {
      editPaymentInfoClick(
        payPlanInfo,
        accountNumber,
        awsAppSyncClient,
        SSOneINCURL,
      );
    }

    simpleGTMDataLayer({
      event: 'BillingPayments-EditPaymentInfo-click',
      event_action: 'Billing Payments Edit Payment Info Click',
      event_category: 'Billing Payments Edit Payment Info',
      event_label: 'Billing Payments Edit Payment Info Click',
    });

    simpleGTMDataLayer({
      event: 'Snowplow-BillingPayments-EditPaymentInfo-click',
      event_action: 'Snowplow Billing Payments Edit Payment Info Click',
      event_category: 'Snowplow Billing Payments Edit Payment Info',
      event_label: 'Snowplow Billing Payments Edit Payment Info Click',
    });
  };

  if (payPlanInfo && !payPlanInfo.length) {
    return (
      <SACard
        variant="standard"
        title="Pay Plans"
        actionLabel="EDIT PAYMENT INFO"
        actionLabelClickHandler={() => {}}
      >
        <Container numberOfPlans={0}>
          <SAText
            type="standard"
            text="No payment plans found for this account"
          />
        </Container>
      </SACard>
    );
  }

  return (
    <>
      {showOutageModal && (
        <OutageModal
          component="Edit Payment Info"
          setModal={setShowOutageModal}
        />
      )}
      <SACard
        variant="standard"
        title="Pay Plans"
        actionLabel="EDIT PAYMENT INFO"
        actionLabelClickHandler={editPaymentInfoClickHandler}
      >
        <Container className="pay-plans" numberOfPlans={payPlanInfo?.length}>
          <LeftSide>
            <SideBySide>
              <SAText type="heading-4" text="Current Policies" />
              <SAText
                type="small"
                text="Disclaimer: The displayed amounts do not include any billing fees."
              />
            </SideBySide>
            <Spacer />
            <SATable
              columns={columns}
              data={payPlanTableInfo}
              variant="table-to-listview"
            />
          </LeftSide>
          <RightSide>
            <RightSideHeading>
              <SAText type="heading-4" text="Payment Method" />
            </RightSideHeading>
            <GrayBox>
              <BankAccount>
                <Heading>
                  <SAText text="Bank Account" type="small" />
                </Heading>
                <SAText
                  text={paymentMethod}
                  type={
                    `${
                      paymentMethod.length > 17 ? 'small' : 'standard'
                    }` as SATextType
                  }
                  weight="bold"
                />
              </BankAccount>
              <AltBankAccount>
                <Heading>
                  <SAText text="Bank Account" />
                </Heading>
                <SAText text={paymentMethod} weight="bold" />
              </AltBankAccount>
            </GrayBox>
          </RightSide>
        </Container>
      </SACard>
    </>
  );
};

PayPlans.defaultProps = {
  loading: false,
  error: undefined,
};

export default PayPlans;
