/* eslint-disable */
import React, { MutableRefObject, useEffect } from 'react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import moment from 'moment-timezone';
import { StopPaymentButton } from '.';
import {
  GET_CANCELEDPAYMENT_INVOICES,
  GET_CANCELEDPAYMENT_INVOICESVariables,
  GET_CANCELEDPAYMENT_INVOICES_invoices_items,
} from '../../generated/GET_CANCELPAYMENT_INVOICES';
import { formatDate } from '../../util/formatters';
import { ToolTip } from '../stopPaymentTooltips/DesktopTooltip';

const getCanceledPaymentQuery = loader(
  '../../graphql/query/Get_CancelPayment_Invoices.graphql',
);

interface StopPaymentButtonDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  setHasStoppedPayment: Function;
  setShowStopPaymentModal: Function;
  setCancelPaymentDisabled: Function;
  cancelPaymentDisabled: boolean;
  setShowToolTip?: Function;
  toolTip: MutableRefObject<ToolTip>;
  setShowOutageModal: Function;
  setWhichComponent: Function;
}

interface ProcessCanceledPaymentQueryProps {
  apolloQueryResult: ApolloQueryResult<GET_CANCELEDPAYMENT_INVOICES>;
  setCancelPaymentDisabled: Function;
  setHasStoppedPayment: Function;
  toolTip: MutableRefObject<ToolTip>;
}

export function fetchCancelPaymentInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_CANCELEDPAYMENT_INVOICES>> {
  const variables: GET_CANCELEDPAYMENT_INVOICESVariables = {
    account_number: accountNumber,
  } as GET_CANCELEDPAYMENT_INVOICESVariables;

  const queryResult: Promise<ApolloQueryResult<GET_CANCELEDPAYMENT_INVOICES>> =
    awsAppSyncClient.query({
      query: getCanceledPaymentQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_CANCELEDPAYMENT_INVOICES>>;
  return queryResult;
}

export const filterNonCancelablePayments = (
  invoices: (GET_CANCELEDPAYMENT_INVOICES_invoices_items | null)[],
) => {
  const filteredInvoices: GET_CANCELEDPAYMENT_INVOICES_invoices_items[] =
    invoices.filter(
      (
        invoice: GET_CANCELEDPAYMENT_INVOICES_invoices_items | null,
      ): invoice is GET_CANCELEDPAYMENT_INVOICES_invoices_items => {
        if (!invoice?.pmt_draft_date) {
          return false;
        }
        const draftDate = moment.tz(
          invoice.pmt_draft_date,
          'YYYY-MM-DD',
          'America/New_York',
        );
        const currentDate = moment.tz('America/New_York');
        const isBeforeDraftDate =
          currentDate.isBefore(draftDate) ||
          (currentDate.isSameOrBefore(draftDate, 'days') &&
            invoice.pmt_status === 'Canceled');
        if (
          isBeforeDraftDate &&
          currentDate.isSameOrAfter(draftDate.clone().subtract(10, 'days'))
        ) {
          return true;
        }
        return false;
      },
    );

  return filteredInvoices;
};

export const containsDesiredPaymentStatus = (
  invoices: GET_CANCELEDPAYMENT_INVOICES_invoices_items[],
  desiredStatus: string,
) => {
  let containsDesiredStatus: boolean = false;
  let firstInvoiceWithStatus: GET_CANCELEDPAYMENT_INVOICES_invoices_items | null =
    null;
  for (let i = 0; i < invoices.length; i += 1) {
    if (invoices[i].pmt_status === desiredStatus) {
      containsDesiredStatus = true;
      firstInvoiceWithStatus = invoices[i];
      break;
    }
  }
  return {
    containsDesiredStatus,
    firstInvoiceWithStatus,
  };
};

export const processCanceledPaymentQuery = ({
  apolloQueryResult,
  setCancelPaymentDisabled,
  setHasStoppedPayment,
  toolTip,
}: ProcessCanceledPaymentQueryProps) => {
  const filteredNonCancelablePayments: GET_CANCELEDPAYMENT_INVOICES_invoices_items[] =
    filterNonCancelablePayments(apolloQueryResult.data?.invoices?.items || []);
  const containsCanceled = containsDesiredPaymentStatus(
    filteredNonCancelablePayments,
    'Canceled',
  );
  if (
    containsDesiredPaymentStatus(filteredNonCancelablePayments, 'Requested')
      .containsDesiredStatus
  ) {
    setCancelPaymentDisabled(false);
  } else if (
    filteredNonCancelablePayments.length &&
    containsCanceled.containsDesiredStatus
  ) {
    setCancelPaymentDisabled(true);
    setHasStoppedPayment(true);
    toolTip.current = {
      title: '',
      subtitle: `The automatic payment due on ${formatDate(
        containsCanceled.firstInvoiceWithStatus?.pmt_draft_date,
      )} has been stopped.`,
    };
  } else {
    setCancelPaymentDisabled(true);
    toolTip.current = {
      title: '',
      subtitle: 'Payments can only be stopped within 10 days of the due date.',
    };
  }
};

const StopPaymentButtonDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  setHasStoppedPayment,
  setShowStopPaymentModal,
  setCancelPaymentDisabled,
  cancelPaymentDisabled,
  setShowToolTip,
  toolTip,
  setShowOutageModal,
  setWhichComponent,
}: StopPaymentButtonDataContainerProps) => {
  useEffect(() => {
    fetchCancelPaymentInfo(awsAppSyncClient, accountNumber)
      .then(
        (
          apolloQueryResult: ApolloQueryResult<GET_CANCELEDPAYMENT_INVOICES>,
        ) => {
          processCanceledPaymentQuery({
            apolloQueryResult,
            setCancelPaymentDisabled,
            setHasStoppedPayment,
            toolTip,
          });
        },
      )
      .catch((err) =>
        console.error('GET_CANCELEDPAYMENT_INVOICES ERROR: ', err),
      );
  }, [accountNumber]);

  return (
    <StopPaymentButton
      cancelPaymentDisabled={cancelPaymentDisabled}
      setShowStopPaymentModal={setShowStopPaymentModal}
      setShowToolTip={setShowToolTip}
      toolTip={toolTip}
      setShowOutageModal={setShowOutageModal}
      setWhichComponent={setWhichComponent}
    />
  );
};

export default StopPaymentButtonDataContainer;
