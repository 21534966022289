import React from 'react';
import { InvoiceDetailsProps } from '../invoiceDetails/DataContainer';
import BillingHistorySubMenu from './BillingHistorySubMenu';

interface BillingHistorySubMenuContainerProps extends InvoiceDetailsProps {
  closeModalHandler: Function;
  displayModal: boolean;
  onDocumentClick: Function;
  documentsModal: any;
}

const BillingHistorySubMenuDataContainer = ({
  invoiceNum,
  dueDate,
  contentTags,
  accountNumber,
  appSyncClient,
  docId,
  closeModalHandler,
  displayModal,
  onDocumentClick,
  documentsModal,
}: BillingHistorySubMenuContainerProps): JSX.Element => {
  return (
    <>
      {documentsModal}
      <BillingHistorySubMenu
        invoiceNum={invoiceNum}
        dueDate={dueDate}
        contentTags={contentTags}
        accountNumber={accountNumber}
        appSyncClient={appSyncClient}
        docId={docId}
        downloadInvoiceOnClick={onDocumentClick}
        closeModalHandler={closeModalHandler}
        displayModal={displayModal}
      />
    </>
  );
};

export default BillingHistorySubMenuDataContainer;
