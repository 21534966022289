import { GlobalStyle, SAUX360Theme } from '@saux/design-system-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import BillingPayments from './BillingPayments';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    renderBillingPayments: Function;
    unmountCreatereactapp: Function;
  }
}

window.renderBillingPayments = (containerId: string, session: any) => {
  ReactDOM.render(
    <ThemeProvider theme={SAUX360Theme}>
      <GlobalStyle />
      <BillingPayments session={session} />
    </ThemeProvider>,
    document.getElementById(containerId),
  );
  serviceWorker.unregister();
};

// window.unmountCreatereactapp = (containerId: string) => {
//   containerId ? ReactDOM.unmountComponentAtNode(document.getElementById(containerId)) : '';
// };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
