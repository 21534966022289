import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

/** this centers the modal vertically and horizontally */
export const DesktopModalBody = styled.div`
  display: none;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: block;
    height: 500px;
    width: ${customBase.global.breakpoints.medium}px;

    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

export const MobileModalBody = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: block;
    height: 100vh;
    background-color: ${theme.color.container.background};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      display: none;
    }
  `}
`;

export const TopSectionWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.size.spacer.medium};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      width: 100%;
      margin-bottom: 0px;
      flex-direction: row;
    }
  `}
`;

export const DueDateWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    flex-direction: row;
    height: 26px;
    margin-bottom: ${theme.size.spacer.small};
    margin-left: ${theme.size.spacer.medium};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      flex-direction: row;
      margin-bottom: ${theme.size.spacer.large};
      margin-left: 0px;
    }
  `}
`;

export const DueDate = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    margin-left: ${theme.size.spacer.xs};
  `}
`;

export const ContentTags = styled.div`
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    margin-left: 20px;
  }
`;

export const TagWrapper = styled.span`
  ${({ theme }: { theme: SAUXTheme }) => `
  margin: 0px ${theme.size.spacer.small} 0px 0px;
  @media screen and (max-width: ${customBase.global.breakpoints.large}px) {
    margin: 0px 0px 0px ${theme.size.spacer.small};
  }
`}
`;

export const TotalCard = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    flex-direction: column;
    padding: ${theme.size.spacer.small};
    margin-top: ${theme.size.spacer.medium};
    background-color: #f5f7fa;
    width: 100%;

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      width: 65%;
      justify-content: flex-end;
    }
  `}
`;

export const TotalCardWrapper = styled.div`
  display: flex;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    justify-content: flex-end;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    justify-content: flex-end;
  }
`;

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButtonWrapper = styled.span`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    position: absolute;
    top: 18px;
    right: ${theme.size.spacer.medium};
    justify-content: flex-end;
    padding-right: ${theme.size.spacer.medium};

    *:first-child:hover {
      cursor: pointer;
    }
  `}
`;

export const LoadingErrorCard = styled.div`
  display: block;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;

    /* this centers the modal vertically and horizontally */
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;
