/* eslint-disable react/require-default-props */
import { SAButton } from '@saux/design-system-react';
import React, { MutableRefObject, useState } from 'react';
import customBase from '../../util/global.theme';
import { useFeatureFlag } from '../../util/hooks';
import useIsResponsive from '../../util/useIsResponsive';
import DesktopTooltip, { ToolTip } from '../stopPaymentTooltips/DesktopTooltip';
import { ResponsiveTooltipIcon } from '../stopPaymentTooltips/ResponsiveTooltip';

interface StopPaymentButtonProps {
  cancelPaymentDisabled: boolean;
  setShowStopPaymentModal: Function;
  toolTip: MutableRefObject<ToolTip>;
  setShowToolTip?: Function;
  setShowOutageModal: Function;
  setWhichComponent: Function;
}

const StopPaymentButton = ({
  cancelPaymentDisabled,
  setShowStopPaymentModal,
  toolTip,
  setShowToolTip,
  setShowOutageModal,
  setWhichComponent,
}: StopPaymentButtonProps) => {
  const [desktopToolTip, setDesktopToolTip] = useState<boolean>(false);
  const isResponsive = useIsResponsive();
  const { flagDetails: BillingCenterOutage } = useFeatureFlag(
    'BillingCenterOutage',
  );

  if (isResponsive && setShowToolTip) {
    return (
      <>
        <div>
          <SAButton
            className="overview-stop-payment-tour"
            fullWidth
            fullWidthUnder={customBase.global.breakpoints.medium}
            label="STOP PAYMENT"
            test-attr="stop-payment-button"
            disabled={cancelPaymentDisabled}
            variant="secondary-outline"
            onClick={() => {
              if (BillingCenterOutage?.enabled) {
                setWhichComponent('Stop Upcoming Payment');
                setShowOutageModal(true);
              } else {
                setShowStopPaymentModal(true);
                document.body.style.overflow = 'hidden';
              }
            }}
          />
        </div>
        {cancelPaymentDisabled && (
          <ResponsiveTooltipIcon setShowToolTip={setShowToolTip} />
        )}
      </>
    );
  }

  return (
    <>
      <SAButton
        className="overview-stop-payment-tour"
        label="STOP PAYMENT"
        test-attr="stop-payment-button"
        disabled={cancelPaymentDisabled}
        variant="secondary-small-outline"
        onClick={() => {
          if (BillingCenterOutage?.enabled) {
            setWhichComponent('Stop Upcoming Payment');
            setShowOutageModal(true);
          } else {
            setShowStopPaymentModal(true);
            document.body.style.overflow = 'hidden';
          }
        }}
      />
      {cancelPaymentDisabled && (
        <DesktopTooltip
          toolTip={toolTip}
          setDesktopToolTip={setDesktopToolTip}
          desktopToolTip={desktopToolTip}
        />
      )}
    </>
  );
};

export default StopPaymentButton;
