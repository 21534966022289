import { SACard, SAUXTheme } from '@saux/design-system-react';
import AWSAppSyncClient from 'aws-appsync';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BillingHistory,
  NextPayment,
  PaymentHistory,
  PayPlans,
} from './components';
import customBase from './util/global.theme';
import { simpleGTMDataLayer } from './util/GTMHelpers';

export interface BillingPaymentProps {
  session: {
    accountNumber: string;
    awsAppSyncClient: AWSAppSyncClient<any>;
    sourceSystemCode: string;
  };
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    display: flex;
    flex-direction: row;
    margin-top: ${theme.size.spacer.large};
    margin-bottom: ${theme.size.spacer.medium};
    @media (max-width: ${customBase.global.breakpoints.large}px) {
      flex-direction: column;
    }
    `;
  }}
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

function renderContent(
  returnIndex: number,
  session: {
    accountNumber: string;
    awsAppSyncClient: AWSAppSyncClient<any>;
  },
) {
  switch (returnIndex) {
    case 0:
      return (
        <BillingHistory
          accountNumber={session.accountNumber}
          awsAppSyncClient={session.awsAppSyncClient}
        />
      );
    default:
      return (
        <PaymentHistory
          accountNumber={session.accountNumber}
          awsAppSyncClient={session.awsAppSyncClient}
        />
      );
  }
}

const BillingPayments = ({ session }: BillingPaymentProps) => {
  const tabs = ['Invoices', 'Payment History'];
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const tabChanged = (newActiveTabIndex: number) => {
    setActiveTabIndex(newActiveTabIndex);

    simpleGTMDataLayer({
      event: `BillingPayments-${tabs[newActiveTabIndex]} Tab-click`,
      event_action: 'Billing Payments History Table Tab',
      event_category: tabs[newActiveTabIndex],
      event_label: 'Billing Payments History Table Tab',
    });

    simpleGTMDataLayer({
      event: `Snowplow-BillingPayments-${tabs[newActiveTabIndex]} Tab-click`,
      event_action: 'Snowplow Billing Payments History Table Tab',
      event_category: tabs[newActiveTabIndex],
      event_label: 'Snowplow Billing Payments History Table Tab',
    });
  };

  return (
    <Page className="App">
      <Row>
        <Column>
          <NextPayment
            accountNumber={session.accountNumber}
            awsAppSyncClient={session.awsAppSyncClient}
            sourceSystemCode={session.sourceSystemCode}
          />
        </Column>
        <Column style={{ width: '25px', height: '25px' }} />
        <Column style={{ width: '100%' }}>
          <PayPlans
            accountNumber={session.accountNumber}
            awsAppSyncClient={session.awsAppSyncClient}
          />
        </Column>
      </Row>
      <SACard
        variant="standard-tabbed"
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        tabChangeEventHandler={tabChanged}
      >
        {renderContent(activeTabIndex, session)}
      </SACard>
    </Page>
  );
};

export default BillingPayments;
