/* eslint-disable no-param-reassign */
import { FetchResult } from '@apollo/react-hooks';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { MutableRefObject, useEffect, useState } from 'react';
import {
  GET_POLICIES_BY_ACCOUNT,
  GET_POLICIES_BY_ACCOUNTVariables,
} from '../../generated/GET_POLICIES_BY_ACCOUNT';
import {
  UPDATE_CANCEL_PAYMENT,
  UPDATE_CANCEL_PAYMENTVariables,
} from '../../generated/UPDATE_CANCEL_PAYMENT';
import { ToolTip } from '../stopPaymentTooltips/DesktopTooltip';
import StopPaymentModal from './StopPaymentModal';

const updateCancelPaymentMutation = loader(
  '../../graphql/mutation/Update_Cancel_Payment.graphql',
);

const getPoliciesByAccountQuery = loader(
  '../../graphql/query/Get_Policies_by_Account.graphql',
);

interface StopPaymentModalDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  paymentDueDate: string;
  setCancelPaymentDisabled: Function;
  toolTip: MutableRefObject<ToolTip>;
  closeOnClickHandler: Function;
}

export function fetchPolicies(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>> {
  const variables: GET_POLICIES_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_POLICIES_BY_ACCOUNTVariables;
  const queryResult: Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>> =
    awsAppSyncClient.query({
      query: getPoliciesByAccountQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>>;
  return queryResult;
}

export const updateCancelPayment = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
) => {
  const variables: UPDATE_CANCEL_PAYMENTVariables = {
    account_number: accountNumber,
  };

  return awsAppSyncClient.mutate({
    mutation: updateCancelPaymentMutation,
    variables,
  }) as Promise<FetchResult<UPDATE_CANCEL_PAYMENT>>;
};

const StopPaymentModalDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  paymentDueDate,
  toolTip,
  setCancelPaymentDisabled,
  closeOnClickHandler,
}: StopPaymentModalDataContainerProps) => {
  const [hasMultiplePolicies, setHasMultiplePolicies] = useState<boolean>(true);

  useEffect(() => {
    fetchPolicies(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>) => {
        if (
          apolloQueryResult.data &&
          apolloQueryResult.data.account &&
          apolloQueryResult.data.account?.items &&
          apolloQueryResult.data.account?.items[0]?.policies
        ) {
          setHasMultiplePolicies(
            apolloQueryResult.data.account.items[0].policies.count > 1,
          );
        }
      })
      .catch((err) => console.error('GET_ACCOUNT_POLICIES ERROR: ', err));
  }, [accountNumber]);

  const submitCancelPayment = () => {
    updateCancelPayment(awsAppSyncClient, accountNumber)
      .then(() => {
        setCancelPaymentDisabled(true);
        closeOnClickHandler();
        const newToolTipText = `The automatic payment due on ${paymentDueDate} has been stopped.`;
        toolTip.current = { title: '', subtitle: newToolTipText };
      })
      .catch((err: Error) => {
        console.error('UPDATE CANCEL PAYMENT ERROR: ', err);
      });
  };

  return (
    <StopPaymentModal
      hasMultiplePolicies={hasMultiplePolicies}
      paymentDueDate={paymentDueDate}
      closeOnClickHandler={closeOnClickHandler}
      confirmOnClickHandler={submitCancelPayment}
    />
  );
};

export default StopPaymentModalDataContainer;
