import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_INVOICE_IN_ACCOUNT,
  GET_INVOICE_IN_ACCOUNTVariables,
  GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items,
} from '../../generated/GET_INVOICE_IN_ACCOUNT';
import useDocumentDownload from '../../util/useDocumentDownload';
import InvoiceDetails from './InvoiceDetails';

const getInvoiceInAccountQuery = loader(
  '../../graphql/query/Get_Invoice_In_Account.graphql',
);

export interface InvoiceDetailsProps {
  invoiceNum: number;
  dueDate: string;
  contentTags: (string | null)[];
  accountNumber: string;
  appSyncClient: AWSAppSyncClient<any>;
  docId?: string | null | undefined;
}

export interface InvoiceDetailsContainerProps extends InvoiceDetailsProps {
  update?: any;
  invoiceDetails: any;
  closeModalHandler: Function;
}

export const transformData = (
  dueDate: string,
  contentTags: (string | null)[],
  data: GET_INVOICE_IN_ACCOUNT,
  invoiceNum: number | undefined,
) => {
  let details: (GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items | null)[] =
    [];
  let amountPaidTotal: number = 0;
  let amountBilledTotal: number = 0;
  let amountRemainingTotal: number = 0;
  let docuid: string = '-';

  if (
    data?.account?.items != null &&
    data.account.items[0]?.invoice?.invoice_snapshots?.items != null
  ) {
    details = data.account.items[0].invoice.invoice_snapshots.items;
    amountPaidTotal = data.account.items[0].invoice.paid_amount || 0;
    amountBilledTotal = data.account.items[0].invoice.invoice_amount || 0;
    amountRemainingTotal = data.account.items[0].invoice.amount_due || 0;
    docuid = data.account.items[0].invoice.docuid ?? '-';
  }

  return {
    invoiceNum,
    docuid,
    dueDate,
    contentTags,
    data: details,
    amountPaidTotal,
    amountBilledTotal,
    amountRemainingTotal,
  };
};

export async function fetchInvoiceDetails(
  awsAppSyncClient: AWSAppSyncClient<any>,
  variables: GET_INVOICE_IN_ACCOUNTVariables,
): Promise<ApolloQueryResult<GET_INVOICE_IN_ACCOUNT>> {
  const queryResult: Promise<ApolloQueryResult<GET_INVOICE_IN_ACCOUNT>> =
    awsAppSyncClient.query({
      query: getInvoiceInAccountQuery,
      variables,
    }) as Promise<ApolloQueryResult<GET_INVOICE_IN_ACCOUNT>>;
  return queryResult;
}

const InvoiceDetailsDataContainer = ({
  invoiceNum,
  dueDate,
  contentTags,
  accountNumber,
  appSyncClient,
  update,
  invoiceDetails,
  closeModalHandler,
}: InvoiceDetailsContainerProps) => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  const variables: GET_INVOICE_IN_ACCOUNTVariables = {
    account_number: accountNumber,
    invoice_number: invoiceNum.toString(),
  };

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    appSyncClient,
    accountNumber,
  );

  useEffect(() => {
    fetchInvoiceDetails(appSyncClient, variables)
      .then((apolloQueryResult: ApolloQueryResult<GET_INVOICE_IN_ACCOUNT>) => {
        const processedInvoiceData = transformData(
          dueDate,
          contentTags,
          apolloQueryResult.data,
          invoiceNum,
        );

        update(processedInvoiceData);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error('GET_INVOICE_IN_ACCOUNT ERROR: ', err);
        setError(err);
      });
  }, [invoiceNum]);

  return (
    <>
      {documentsModal}
      <InvoiceDetails
        onDocumentClick={onDocumentClick}
        loading={loading}
        error={error}
        invoiceData={invoiceDetails}
        closeModalHandler={closeModalHandler}
      />
    </>
  );
};

export default InvoiceDetailsDataContainer;
