import React from 'react';
import {
  SAButton,
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SATable,
  SATableColumn,
  SATag,
  SATagProps,
  SAText,
} from '@saux/design-system-react';
import { GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items } from '../../generated/GET_INVOICE_IN_ACCOUNT';
import {
  CloseButtonWrapper,
  ContentTags,
  DesktopModalBody,
  DownloadWrapper,
  DueDate,
  DueDateWrapper,
  LoadingErrorCard,
  MobileModalBody,
  TagWrapper,
  TopSectionWrapper,
  TotalCard,
  TotalCardWrapper,
  TotalRow,
} from './InvoiceDetailsStyles';
import { formatDate, formatMoney } from '../../util/formatters';
import Modal from '../modal';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useIsResponsive from '../../util/useIsResponsive';

export interface InvoiceData {
  invoiceNum: string;
  docuid: string;
  dueDate: string;
  contentTags: (string | null)[];
  data: (GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items | null)[];
  amountPaidTotal: number;
  amountBilledTotal: number;
  amountRemainingTotal: number;
}

interface InvoicePresenterProps {
  loading?: boolean;
  error?: Error;
  invoiceData: InvoiceData;
  closeModalHandler: Function;
  onDocumentClick: Function;
}

const InvoiceDetails = ({
  loading,
  error,
  invoiceData,
  closeModalHandler,
  onDocumentClick,
}: InvoicePresenterProps) => {
  const isResponsive = useIsResponsive();
  if (loading) {
    return (
      <LoadingErrorCard className="invoice-details-modal-loading">
        <SACard variant="minimal">
          <SAText type="standard" text="Loading..." colorVariant="default" />
        </SACard>
      </LoadingErrorCard>
    );
  }

  if (error) {
    return (
      <LoadingErrorCard className="invoice-details-modal-error">
        <SACard variant="minimal">
          <SAText type="standard" text={error.message} colorVariant="alert" />
        </SACard>
      </LoadingErrorCard>
    );
  }

  function organizeInvoiceDetails(
    data: (GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items | null)[],
  ): any[][] {
    const rowInfo: any[][] = [];
    data.forEach(
      (
        invoice: GET_INVOICE_IN_ACCOUNT_account_items_invoice_invoice_snapshots_items | null,
      ) => {
        const columnInfo: any[] = [
          invoice?.description,
          invoice?.invoice_item_amount,
          invoice?.paid_amount,
          invoice?.amount_due,
        ];
        rowInfo.push(columnInfo);
      },
    );
    return rowInfo;
  }

  function processContents(contents: (string | null)[]) {
    const pills: JSX.Element[] = contents?.map(
      (tag: string | null, index: number) => {
        let variant: SATagProps['variant'] = 'secondary-outline';
        switch (tag) {
          case 'Cancellation':
            variant = 'alert-outline';
            break;
          case 'Invoice':
            variant = 'standard-outline';
            break;
          default:
            break;
        }
        const key: string = `${tag}-${index}`;
        return (
          <TagWrapper>
            <SATag key={key} label={tag || ''} variant={variant} />
          </TagWrapper>
        );
      },
    );
    return pills;
  }

  function processMoney(amount: number): JSX.Element {
    const formattedMoney: string | null = formatMoney(amount) ?? '$0';
    if (formattedMoney && formattedMoney[0] === '(') {
      return (
        <SAText
          type="standard"
          text={formattedMoney}
          colorVariant="alert-background"
        />
      );
    }

    return (
      <SAText type="standard" text={formattedMoney} colorVariant="default" />
    );
  }

  const columns: SATableColumn[] = [
    {
      align: 'center',
      name: 'Description',
    },
    {
      align: 'center',
      name: 'Amt. Billed',
      process: processMoney,
    },
    {
      align: 'center',
      name: 'Amt. Paid',
      process: processMoney,
    },
    {
      align: 'center',
      name: 'Amt. Remaining',
      process: processMoney,
    },
  ];

  const dueDate: string | undefined = formatDate(invoiceData.dueDate);
  const organizedInvoiceData: any[][] = organizeInvoiceDetails(
    invoiceData.data,
  );
  const totalAmountBilled: string =
    formatMoney(invoiceData.amountBilledTotal) || '-';
  const totalAmountPaid: string =
    formatMoney(invoiceData.amountPaidTotal) || '-';
  const totalAmountRemaining: string =
    formatMoney(invoiceData.amountRemainingTotal) || '-';

  const ModalBody = () => (
    <SACard variant="simple" title="Invoice Breakdown">
      <CloseButtonWrapper className="invoice-details-close-button">
        <SAIcon
          icon={SAIcons.x}
          size={SAIconSize.medium}
          colorVariant="dark"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            closeModalHandler();
            document.body.style.overflow = 'auto';
          }}
        />
      </CloseButtonWrapper>
      <TopSectionWrapper>
        <DueDateWrapper>
          <SAText type="paragraph" text="Due Date: " />
          <DueDate>
            <SAText type="paragraph" text={dueDate} weight="bold" />
          </DueDate>
        </DueDateWrapper>
        <ContentTags>{processContents(invoiceData.contentTags)}</ContentTags>
      </TopSectionWrapper>
      <SATable
        columns={columns}
        data={organizedInvoiceData}
        variant="table-to-listview"
        onRowClick={() => {}}
      />
      <TotalCardWrapper>
        <TotalCard>
          <TotalRow>
            <SAText type="small" text="Total Amt. Billed" weight="bold" />
            <SAText type="paragraph" text={totalAmountBilled} weight="bold" />
          </TotalRow>
          <TotalRow>
            <SAText type="small" text="Total Amt. Paid" weight="bold" />
            <SAText type="paragraph" text={totalAmountPaid} weight="bold" />
          </TotalRow>
          <TotalRow>
            <SAText type="small" text="Total Amt. Remaining" weight="bold" />
            <SAText
              type="paragraph"
              text={totalAmountRemaining}
              weight="bold"
            />
          </TotalRow>
        </TotalCard>
      </TotalCardWrapper>
      {invoiceData.docuid !== '-' && (
        <DownloadWrapper
          className="download-invoice-button"
          id="download-invoice-button"
          data-testid="download-invoice-button"
        >
          <SAButton
            variant="primary"
            label="Download Invoice"
            onClick={() => {
              if (onDocumentClick) {
                onDocumentClick({
                  invoice_number: invoiceData.invoiceNum,
                });
              }

              simpleGTMDataLayer({
                event:
                  'BillingPayments-InvoiceDetailsDownloadInvoiceButton-click',
                event_action:
                  'Billing Payments Invoice Details Download Invoice Button Click',
                event_category:
                  'Billing Payments Invoice Details Download Invoice Button',
                event_label:
                  'Billing Payments Invoice Details Download Invoice Button Click',
              });

              simpleGTMDataLayer({
                event:
                  'Snowplow-BillingPayments-InvoiceDetailsDownloadInvoiceButton-click',
                event_action:
                  'Snowplow Billing Payments Invoice Details Download Invoice Button Click',
                event_category:
                  'Snowplow Billing Payments Invoice Details Download Invoice Button',
                event_label:
                  'Snowplow Billing Payments Invoice Details Download Invoice Button Click',
              });
            }}
          />
        </DownloadWrapper>
      )}
    </SACard>
  );

  return (
    <Modal>
      {isResponsive ? (
        <MobileModalBody className="invoice-details-modal">
          <ModalBody />
        </MobileModalBody>
      ) : (
        <DesktopModalBody className="invoice-details-modal">
          <ModalBody />
        </DesktopModalBody>
      )}
    </Modal>
  );
};

InvoiceDetails.defaultProps = {
  loading: false,
  error: undefined,
};

export default InvoiceDetails;
