import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import { ReactComponent as Success } from '../../assets/images/Success.svg';
import { ReactComponent as CloseSmall } from '../../assets/images/CloseSmallIcon.svg';
import daysOfTheMonth from '../../util/generators/daysOfTheMonth';
import {
  Backdrop,
  ButtonHolder,
  CalenderHolder,
  CurrentDueDate,
  DateGrid,
  Footer,
  Header,
  HeaderParagraph,
  Modal,
  NewDueDate,
  NotSelectableDate,
  SelectableDate,
  IconHolder,
  FullSizeBlueMessage,
  FullSizeYellowMessage,
  MessageInnerText,
  CloseIcon,
  HeaderHolder,
  HeaderText,
  MobileWordingRow,
  MobileHeader,
  DesktopHeader,
  ResponsiveFooter,
  CloseSizing,
  CheckSizing,
  SuccessTooltip,
  FailureTooltip,
  FinalMessageInnerText,
} from './ChangeDueDateModalStyles';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

interface ChangeDueDateModalProps {
  loading: boolean;
  error: Error | undefined;
  setShowModal: Function;
  currentDueDate: string;
  setUpdateToNewDueDate: Function;
  alreadyChanged: boolean;
  noUpcomingInvoices: boolean;
  mutationFailed: boolean;
  mutationSucceeded: boolean;
  cancelPaymentDisabled: boolean;
  showPastDue: boolean;
}

interface ConfirmModalProps {
  setShowModal: Function;
  newDueDate: string | undefined;
  setShowConfirmModal: Function;
  setUpdateToNewDueDate: Function;
}

export const daySuffix: Function = (currentDate: string): string => {
  if (currentDate === '1' || currentDate === '21' || currentDate === '31')
    return 'st';
  if (currentDate === '2' || currentDate === '22') return 'nd';
  if (currentDate === '3' || currentDate === '23') return 'rd';
  return 'th';
};

const modalTextContentProvider = {
  modalHeaderText:
    'Select the day you would like as your new payment due date, please note you may only change your payment due date once a year.',
  defaultYellowModalText:
    'Payment due dates are locked 10 days before an auto payment is due. The day of the month you have selected will not apply until your next planned payment date.',
  lastDaysOfMonthYellowModalWarning:
    'The day selected does not exist in every month of the year. In the months that are missing the selected day (i.e. February), the scheduled withdrawal date will be the last day of the month.',
  pastDueAndNoScheduledPayment:
    'There is a past due payment on your account. The day of the month you have selected will not apply until your next planned payment date.',
  pastDueAndScheduledPayment:
    'There is a past due and a scheduled payment on your account. The day of the month you have selected will not apply until your next planned payment date.',
};

export const ConfirmModal: Function = ({
  setShowModal,
  newDueDate,
  setShowConfirmModal,
  setUpdateToNewDueDate,
}: ConfirmModalProps) => {
  return (
    <Backdrop>
      <Modal test-attr="change-due-date-modal">
        <MobileHeader>
          <HeaderParagraph>
            <HeaderHolder>
              <HeaderText>Change Due Date</HeaderText>
              <CloseIcon
                test-attr="confirm-change-due-date-cancel-option"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <SAIcon
                  icon={SAIcons.x}
                  size={SAIconSize.medium}
                  colorVariant="dark"
                />
              </CloseIcon>
            </HeaderHolder>
          </HeaderParagraph>
          <HeaderParagraph>
            <SAText
              type="standard"
              text={`Please confirm this is correct as you are\u00A0`}
            />
            <SAText
              type="standard"
              weight="bold"
              text="only able to change your payment due date once every 365 days."
            />
          </HeaderParagraph>
          <MobileWordingRow />
        </MobileHeader>
        <DesktopHeader>
          <Header>
            <SAText type="heading-2" text="Change Due Date" />
          </Header>
          <HeaderParagraph>
            <SAText
              type="standard"
              text={`Please confirm this is correct as you are\u00A0`}
            />
            <SAText
              type="standard"
              weight="bold"
              text="only able to change your payment due date once every 365 days."
            />
          </HeaderParagraph>
        </DesktopHeader>
        <FullSizeBlueMessage>
          <IconHolder>
            <SAIcon
              icon={SAIcons.information}
              size={SAIconSize.medium}
              colorVariant="primary"
            />
          </IconHolder>
          <MessageInnerText>
            <SAText type="standard" text="You have selected the" />
            <SAText
              type="standard"
              weight="bold"
              text={`\u00A0${newDueDate}${daySuffix(newDueDate)}\u00A0`}
            />
            <SAText
              type="standard"
              text="of each month for your new payment due date."
            />
          </MessageInnerText>
        </FullSizeBlueMessage>
        <CalenderHolder>
          <DateGrid>
            {daysOfTheMonth.map((day: string) => {
              if (newDueDate && newDueDate === day) {
                return (
                  <NewDueDate key={day} type="button">
                    {day}
                  </NewDueDate>
                );
              }
              if (day === '31-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    31
                  </NotSelectableDate>
                );
              }
              if (day === '1-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    1
                  </NotSelectableDate>
                );
              }
              if (day === '2-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    2
                  </NotSelectableDate>
                );
              }
              if (day === '3-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    3
                  </NotSelectableDate>
                );
              }
              return (
                <NotSelectableDate key={day} type="button" disabled>
                  {day}
                </NotSelectableDate>
              );
            })}
          </DateGrid>
        </CalenderHolder>
        <Footer>
          <ButtonHolder>
            <SAButton
              test-attr="confirm-change-due-date-modal-back-button"
              fullWidth={false}
              label="BACK"
              onClick={() => {
                setShowConfirmModal(false);
                setShowModal(true);
              }}
              variant="secondary-link-large"
            />
          </ButtonHolder>
          <ButtonHolder>
            <SAButton
              fullWidth={false}
              test-attr="confirm-change-due-date-modal-confirm-button"
              label="CONFIRM"
              onClick={() => {
                if (newDueDate) {
                  setUpdateToNewDueDate(parseInt(newDueDate, 10));
                  simpleGTMDataLayer({
                    event:
                      'BillingPayments-ChangeDueDate-ConfirmDateChange-click',
                    event_action: 'Billing Payments Confirm Date Change Click',
                    event_category: 'Billing Payments Confirm Date Change',
                    event_label: 'Billing Payments Confirm Date Change',
                  });
                }
                setShowConfirmModal(false);
              }}
              variant="primary"
            />
          </ButtonHolder>
        </Footer>
        <ResponsiveFooter>
          <SAButton
            test-attr="confirm-change-due-date-modal-back-button"
            fullWidth
            label="BACK"
            onClick={() => {
              setShowConfirmModal(false);
              setShowModal(true);
            }}
            variant="secondary-link-large"
          />
          <SAButton
            fullWidth
            test-attr="confirm-change-due-date-modal-confirm-button"
            label="CONFIRM"
            onClick={() => {
              if (newDueDate) {
                setUpdateToNewDueDate(parseInt(newDueDate, 10));
                simpleGTMDataLayer({
                  event:
                    'BillingPayments-ChangeDueDate-ConfirmDateChange-click',
                  event_action: 'Billing Payments Confirm Date Change Click',
                  event_category: 'Billing Payments Confirm Date Change',
                  event_label: 'Billing Payments Confirm Date Change',
                });
              }
              setShowConfirmModal(false);
            }}
            variant="primary"
          />
        </ResponsiveFooter>
      </Modal>
    </Backdrop>
  );
};

const ChangeDueDateModal = ({
  loading,
  error,
  setShowModal,
  currentDueDate,
  setUpdateToNewDueDate,
  alreadyChanged,
  noUpcomingInvoices,
  mutationFailed,
  mutationSucceeded,
  cancelPaymentDisabled,
  showPastDue,
}: ChangeDueDateModalProps) => {
  const [newDueDate, setNewDueDate] = useState<string | undefined>(undefined);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (mutationSucceeded || mutationFailed) {
      setTimeout(() => {
        setShowModal(false);
      }, 5000);
    }
  }, [mutationFailed, mutationSucceeded]);

  if (loading) {
    return (
      <Backdrop>
        <Modal test-attr="change-due-date-modal">
          <MobileHeader>
            <HeaderParagraph>
              <HeaderHolder>
                <HeaderText>Change Due Date</HeaderText>
                <CloseIcon
                  test-attr="change-due-date-cancel-option"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <SAIcon
                    icon={SAIcons.x}
                    size={SAIconSize.medium}
                    colorVariant="dark"
                  />
                </CloseIcon>
              </HeaderHolder>
            </HeaderParagraph>
            <MobileWordingRow />
          </MobileHeader>
          <DesktopHeader>
            <Header>
              <SAText type="heading-2" text="Change Due Date" />
            </Header>
          </DesktopHeader>
          <HeaderParagraph>
            <SAText text="Loading..." />
          </HeaderParagraph>
          <Footer>
            <ButtonHolder>
              <SAButton
                test-attr="change-due-date-modal-back-button"
                fullWidth={false}
                label="CANCEL"
                onClick={() => {
                  setShowModal(false);
                }}
                variant="secondary-link-large"
              />
            </ButtonHolder>
            <ButtonHolder>
              <SAButton
                disabled
                fullWidth={false}
                test-attr="change-due-date-modal-next-button"
                label="NEXT"
                variant="primary"
              />
            </ButtonHolder>
          </Footer>
          <ResponsiveFooter>
            <SAButton
              test-attr="change-due-date-modal-back-button"
              fullWidth
              label="CANCEL"
              onClick={() => {
                setShowModal(false);
              }}
              variant="secondary-link-large"
            />
            <SAButton
              fullWidth
              disabled
              test-attr="change-due-date-modal-next-button"
              label="NEXT"
              variant="primary"
            />
          </ResponsiveFooter>
        </Modal>
      </Backdrop>
    );
  }

  if (error) {
    return (
      <Backdrop>
        <Modal test-attr="change-due-date-modal">
          <MobileHeader>
            <HeaderParagraph>
              <HeaderHolder>
                <HeaderText>Change Due Date</HeaderText>
                <CloseIcon
                  test-attr="change-due-date-cancel-option"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <SAIcon
                    icon={SAIcons.x}
                    size={SAIconSize.medium}
                    colorVariant="dark"
                  />
                </CloseIcon>
              </HeaderHolder>
            </HeaderParagraph>
            <MobileWordingRow />
          </MobileHeader>
          <DesktopHeader>
            <Header>
              <SAText type="heading-2" text="Change Due Date" />
            </Header>
          </DesktopHeader>
          <HeaderParagraph>
            <SAText text="Error" />
          </HeaderParagraph>
          <Footer>
            <ButtonHolder>
              <SAButton
                test-attr="change-due-date-modal-back-button"
                fullWidth={false}
                label="CANCEL"
                onClick={() => {
                  setShowModal(false);
                }}
                variant="secondary-link-large"
              />
            </ButtonHolder>
            <ButtonHolder>
              <SAButton
                disabled
                fullWidth={false}
                test-attr="change-due-date-modal-next-button"
                label="NEXT"
                variant="primary"
              />
            </ButtonHolder>
          </Footer>
          <ResponsiveFooter>
            <SAButton
              test-attr="change-due-date-modal-back-button"
              fullWidth
              label="CANCEL"
              onClick={() => {
                setShowModal(false);
              }}
              variant="secondary-link-large"
            />
            <SAButton
              fullWidth
              disabled
              test-attr="change-due-date-modal-next-button"
              label="NEXT"
              variant="primary"
            />
          </ResponsiveFooter>
        </Modal>
      </Backdrop>
    );
  }

  if (noUpcomingInvoices) {
    return (
      <Backdrop>
        <Modal test-attr="change-due-date-modal">
          <MobileHeader>
            <HeaderParagraph>
              <HeaderHolder>
                <HeaderText>Change Due Date</HeaderText>
                <CloseIcon
                  test-attr="confirm-change-due-date-cancel-option"
                  onClick={() => {
                    setShowModal(false);
                    simpleGTMDataLayer({
                      event:
                        'BillingPayments-ChangeDueDate-DateAlreadyChanged-click',
                      event_action:
                        'Billing Payments Date Already Changed Click',
                      event_category: 'Billing Payments Date Already Changed',
                      event_label:
                        'Billing Payments Date Already Changed Click',
                    });
                  }}
                >
                  <SAIcon
                    icon={SAIcons.x}
                    size={SAIconSize.medium}
                    colorVariant="dark"
                  />
                </CloseIcon>
              </HeaderHolder>
            </HeaderParagraph>
            <MobileWordingRow />
          </MobileHeader>
          <DesktopHeader>
            <Header>
              <SAText type="heading-2" text="Change Due Date" />
            </Header>
          </DesktopHeader>
          <FullSizeBlueMessage>
            <IconHolder>
              <SAIcon
                icon={SAIcons.information}
                size={SAIconSize.medium}
                colorVariant="primary"
              />
            </IconHolder>
            <MessageInnerText>
              <SAText
                type="standard"
                text="This account has no planned invoices and cannot change the due date. Your current due date is the"
              />
              <SAText
                type="standard"
                weight="bold"
                text={`\u00A0${currentDueDate}${daySuffix(currentDueDate)}`}
              />
              <SAText type="standard" text="." />
            </MessageInnerText>
          </FullSizeBlueMessage>
          <CalenderHolder>
            <DateGrid>
              {daysOfTheMonth.map((day: string) => {
                if (currentDueDate && currentDueDate === day) {
                  return (
                    <CurrentDueDate key={day} type="button">
                      {day}
                    </CurrentDueDate>
                  );
                }
                if (day === '31-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      31
                    </NotSelectableDate>
                  );
                }
                if (day === '1-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      1
                    </NotSelectableDate>
                  );
                }
                if (day === '2-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      2
                    </NotSelectableDate>
                  );
                }
                if (day === '3-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      3
                    </NotSelectableDate>
                  );
                }
                return (
                  <SelectableDate key={day} type="button" disabled>
                    {day}
                  </SelectableDate>
                );
              })}
            </DateGrid>
          </CalenderHolder>
          <Footer>
            <ButtonHolder>
              <SAButton
                fullWidth={false}
                test-attr="confirm-change-due-date-modal-return-button"
                label="RETURN"
                onClick={() => {
                  setShowModal(false);
                  simpleGTMDataLayer({
                    event:
                      'BillingPayments-ChangeDueDate-DateAlreadyChanged-click',
                    event_action: 'Billing Payments Date Already Changed Click',
                    event_category: 'Billing Payments Date Already Changed',
                    event_label: 'Billing Payments Date Already Changed Click',
                  });
                }}
                variant="primary"
              />
            </ButtonHolder>
          </Footer>
          <ResponsiveFooter>
            <SAButton
              fullWidth
              test-attr="confirm-change-due-date-modal-return-button"
              label="RETURN"
              onClick={() => {
                setShowModal(false);
                simpleGTMDataLayer({
                  event:
                    'BillingPayments-ChangeDueDate-DateAlreadyChanged-click',
                  event_action: 'Billing Payments Date Already Changed Click',
                  event_category: 'Billing Payments Date Already Changed',
                  event_label: 'Billing Payments Date Already Changed Click',
                });
              }}
              variant="primary"
            />
          </ResponsiveFooter>
        </Modal>
      </Backdrop>
    );
  }

  if (alreadyChanged) {
    return (
      <Backdrop>
        <Modal test-attr="change-due-date-modal">
          <MobileHeader>
            <HeaderParagraph>
              <HeaderHolder>
                <HeaderText>Change Due Date</HeaderText>
                <CloseIcon
                  test-attr="confirm-change-due-date-cancel-option"
                  onClick={() => {
                    setShowModal(false);
                    simpleGTMDataLayer({
                      event:
                        'BillingPayments-ChangeDueDate-DateAlreadyChanged-click',
                      event_action:
                        'Billing Payments Date Already Changed Click',
                      event_category: 'Billing Payments Date Already Changed',
                      event_label:
                        'Billing Payments Date Already Changed Click',
                    });
                  }}
                >
                  <SAIcon
                    icon={SAIcons.x}
                    size={SAIconSize.medium}
                    colorVariant="dark"
                  />
                </CloseIcon>
              </HeaderHolder>
            </HeaderParagraph>
            <MobileWordingRow />
          </MobileHeader>
          <DesktopHeader>
            <Header>
              <SAText type="heading-2" text="Change Due Date" />
            </Header>
          </DesktopHeader>
          <FullSizeBlueMessage>
            <IconHolder>
              <SAIcon
                icon={SAIcons.information}
                size={SAIconSize.medium}
                colorVariant="primary"
              />
            </IconHolder>
            <MessageInnerText>
              <SAText
                type="standard"
                text="You are only able to change your due date once every 365 days. You’ve already changed your due date to the"
              />
              <SAText
                type="standard"
                weight="bold"
                text={`\u00A0${currentDueDate}${daySuffix(
                  currentDueDate,
                )}\u00A0`}
              />
              <SAText type="standard" text="of every month." />
            </MessageInnerText>
          </FullSizeBlueMessage>
          <CalenderHolder>
            <DateGrid>
              {daysOfTheMonth.map((day: string) => {
                if (currentDueDate && currentDueDate === day) {
                  return (
                    <CurrentDueDate key={day} type="button">
                      {day}
                    </CurrentDueDate>
                  );
                }
                if (day === '31-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      31
                    </NotSelectableDate>
                  );
                }
                if (day === '1-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      1
                    </NotSelectableDate>
                  );
                }
                if (day === '2-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      2
                    </NotSelectableDate>
                  );
                }
                if (day === '3-unavailable') {
                  return (
                    <NotSelectableDate key={day} type="button" disabled>
                      3
                    </NotSelectableDate>
                  );
                }
                return (
                  <SelectableDate key={day} type="button" disabled>
                    {day}
                  </SelectableDate>
                );
              })}
            </DateGrid>
          </CalenderHolder>
          <Footer>
            <ButtonHolder>
              <SAButton
                fullWidth={false}
                test-attr="confirm-change-due-date-modal-return-button"
                label="RETURN"
                onClick={() => {
                  setShowModal(false);
                  simpleGTMDataLayer({
                    event:
                      'BillingPayments-ChangeDueDate-DateAlreadyChanged-click',
                    event_action: 'Billing Payments Date Already Changed Click',
                    event_category: 'Billing Payments Date Already Changed',
                    event_label: 'Billing Payments Date Already Changed Click',
                  });
                }}
                variant="primary"
              />
            </ButtonHolder>
          </Footer>
          <ResponsiveFooter>
            <SAButton
              fullWidth
              test-attr="confirm-change-due-date-modal-return-button"
              label="RETURN"
              onClick={() => {
                setShowModal(false);
                simpleGTMDataLayer({
                  event:
                    'BillingPayments-ChangeDueDate-DateAlreadyChanged-click',
                  event_action: 'Billing Payments Date Already Changed Click',
                  event_category: 'Billing Payments Date Already Changed',
                  event_label: 'Billing Payments Date Already Changed Click',
                });
              }}
              variant="primary"
            />
          </ResponsiveFooter>
        </Modal>
      </Backdrop>
    );
  }

  if (showConfirmModal) {
    return (
      <ConfirmModal
        setShowModal={setShowModal}
        newDueDate={newDueDate}
        setShowConfirmModal={setShowConfirmModal}
        setUpdateToNewDueDate={setUpdateToNewDueDate}
      />
    );
  }

  if (mutationFailed) {
    return (
      <FailureTooltip>
        <SAIcon icon={SAIcons.alert} size={SAIconSize.medium} />
        <FinalMessageInnerText>
          <SAText
            type="standard"
            text="Could not change your due date to the"
          />
          <SAText
            type="standard"
            weight="bold"
            text={`\u00A0${newDueDate}${daySuffix(newDueDate)}`}
          />
          <SAText type="standard" text="." />
        </FinalMessageInnerText>
        <CloseSizing
          test-attr="change-due-date-remove-failure-message-option"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <CloseSmall />
        </CloseSizing>
      </FailureTooltip>
    );
  }

  if (mutationSucceeded) {
    return (
      <SuccessTooltip>
        <CheckSizing>
          <Success />
        </CheckSizing>
        <MessageInnerText>
          <SAText type="standard" text="You have selected the" />
          <SAText
            type="standard"
            weight="bold"
            text={`\u00A0${newDueDate}${daySuffix(newDueDate)}\u00A0`}
          />
          <SAText
            type="standard"
            text="of each month to be your new payment due date."
          />
        </MessageInnerText>
        <CloseSizing
          test-attr="change-due-date-remove-success-message-option"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <CloseSmall />
        </CloseSizing>
      </SuccessTooltip>
    );
  }

  return (
    <Backdrop>
      <Modal test-attr="change-due-date-modal">
        <MobileHeader>
          <HeaderParagraph>
            <HeaderHolder>
              <HeaderText>Change Due Date</HeaderText>
              <CloseIcon
                test-attr="change-due-date-cancel-option"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <SAIcon
                  icon={SAIcons.x}
                  size={SAIconSize.medium}
                  colorVariant="dark"
                />
              </CloseIcon>
            </HeaderHolder>
          </HeaderParagraph>
          <HeaderParagraph>
            <SAText
              type="standard"
              text={modalTextContentProvider.modalHeaderText}
            />
          </HeaderParagraph>
          <MobileWordingRow />
        </MobileHeader>
        <DesktopHeader>
          <Header>
            <SAText type="heading-2" text="Change Due Date" />
          </Header>
          <HeaderParagraph>
            <SAText
              type="standard"
              text={modalTextContentProvider.modalHeaderText}
            />
          </HeaderParagraph>
        </DesktopHeader>
        <FullSizeBlueMessage>
          <IconHolder>
            <SAIcon
              icon={SAIcons.information}
              size={SAIconSize.medium}
              colorVariant="primary"
            />
          </IconHolder>
          <MessageInnerText>
            <SAText type="standard" text="Your current due date is the" />
            <SAText
              type="standard"
              weight="bold"
              text={`\u00A0${currentDueDate}${daySuffix(currentDueDate)}\u00A0`}
            />
            <SAText type="standard" text="of every month." />
          </MessageInnerText>
        </FullSizeBlueMessage>
        <CalenderHolder>
          <DateGrid
            onClick={(evt: BaseSyntheticEvent) => {
              if (
                evt &&
                evt.target &&
                evt.target.innerText &&
                evt.target.innerText < 32 &&
                evt.target.innerText !== currentDueDate
              ) {
                setNewDueDate(evt.target.innerText);
              }
            }}
          >
            {daysOfTheMonth.map((day: string) => {
              if (newDueDate && newDueDate === day) {
                return (
                  <NewDueDate key={day} type="button">
                    {day}
                  </NewDueDate>
                );
              }
              if (currentDueDate === day) {
                return (
                  <CurrentDueDate key={day} type="button">
                    {day}
                  </CurrentDueDate>
                );
              }
              if (day === '31-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    31
                  </NotSelectableDate>
                );
              }
              if (day === '1-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    1
                  </NotSelectableDate>
                );
              }
              if (day === '2-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    2
                  </NotSelectableDate>
                );
              }
              if (day === '3-unavailable') {
                return (
                  <NotSelectableDate key={day} type="button" disabled>
                    3
                  </NotSelectableDate>
                );
              }
              return (
                <SelectableDate
                  test-attr="change-due-date-modal-available-day"
                  key={day}
                  type="button"
                >
                  {day}
                </SelectableDate>
              );
            })}
          </DateGrid>
        </CalenderHolder>
        {!cancelPaymentDisabled && showPastDue && (
          <FullSizeYellowMessage>
            <IconHolder>
              <SAIcon
                icon={SAIcons.alert}
                size={SAIconSize.medium}
                colorVariant="tertiary"
              />
            </IconHolder>
            <MessageInnerText>
              <SAText
                type="paragraph"
                text={
                  newDueDate && parseInt(newDueDate, 10) > 28
                    ? modalTextContentProvider.lastDaysOfMonthYellowModalWarning
                    : modalTextContentProvider.pastDueAndNoScheduledPayment
                }
              />
            </MessageInnerText>
          </FullSizeYellowMessage>
        )}
        {!cancelPaymentDisabled && !showPastDue && (
          <FullSizeYellowMessage>
            <IconHolder>
              <SAIcon
                icon={SAIcons.alert}
                size={SAIconSize.medium}
                colorVariant="tertiary"
              />
            </IconHolder>
            <MessageInnerText>
              <SAText
                type="paragraph"
                text={
                  newDueDate && parseInt(newDueDate, 10) > 28
                    ? modalTextContentProvider.lastDaysOfMonthYellowModalWarning
                    : modalTextContentProvider.defaultYellowModalText
                }
              />
            </MessageInnerText>
          </FullSizeYellowMessage>
        )}
        {cancelPaymentDisabled &&
          showPastDue &&
          newDueDate &&
          parseInt(newDueDate, 10) <= 28 && (
            <FullSizeYellowMessage>
              <IconHolder>
                <SAIcon
                  icon={SAIcons.alert}
                  size={SAIconSize.medium}
                  colorVariant="tertiary"
                />
              </IconHolder>
              <MessageInnerText>
                <SAText
                  type="paragraph"
                  text={modalTextContentProvider.pastDueAndScheduledPayment}
                />
              </MessageInnerText>
            </FullSizeYellowMessage>
          )}
        {cancelPaymentDisabled && newDueDate && parseInt(newDueDate, 10) > 28 && (
          <FullSizeYellowMessage>
            <IconHolder>
              <SAIcon
                icon={SAIcons.alert}
                size={SAIconSize.medium}
                colorVariant="tertiary"
              />
            </IconHolder>
            <MessageInnerText>
              <SAText
                type="paragraph"
                text={
                  modalTextContentProvider.lastDaysOfMonthYellowModalWarning
                }
              />
            </MessageInnerText>
          </FullSizeYellowMessage>
        )}
        <Footer>
          <ButtonHolder>
            <SAButton
              test-attr="change-due-date-modal-back-button"
              fullWidth={false}
              label="CANCEL"
              onClick={() => {
                setShowModal(false);
              }}
              variant="secondary-link-large"
            />
          </ButtonHolder>
          <ButtonHolder>
            <SAButton
              fullWidth={false}
              test-attr="change-due-date-modal-next-button"
              label="NEXT"
              onClick={() => {
                if (newDueDate) setShowConfirmModal(true);
              }}
              variant="primary"
            />
          </ButtonHolder>
        </Footer>
        <ResponsiveFooter>
          <SAButton
            test-attr="change-due-date-modal-back-button"
            fullWidth
            label="CANCEL"
            onClick={() => {
              setShowModal(false);
            }}
            variant="secondary-link-large"
          />
          <SAButton
            fullWidth
            test-attr="change-due-date-modal-next-button"
            label="NEXT"
            onClick={() => {
              if (newDueDate) setShowConfirmModal(true);
            }}
            variant="primary"
          />
        </ResponsiveFooter>
      </Modal>
    </Backdrop>
  );
};

export default ChangeDueDateModal;
