import { SAUXTheme } from '@saux/design-system-react';
import * as saux from '@saux/design-tokens/index';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const Container = styled.div`
  ${({
    theme,
    numberOfPlans,
  }: {
    theme: SAUXTheme;
    numberOfPlans: number | undefined | null;
  }) => `
    display: flex;
    flex-flow: row nowrap;
    ${numberOfPlans && numberOfPlans === 1 ? 'height: 161px;' : ''}
    margin-bottom: ${theme.size.spacer.large};
    @media (min-width: 0px) and (max-width: ${
      customBase.global.breakpoints.medium
    }px) {
      flex-flow: column-reverse;
      align-items: center;
      ${numberOfPlans && numberOfPlans === 1 ? 'height: auto;' : ''}
    }
  `}
`;

export const LeftSide = styled.div`
  width: 71%;
  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .medium}px) {
    width: 100%;
  }
`;

export const RightSide = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: 25%;
    margin-left: 4%;
    @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.medium}px) {
      width: 100%;
      margin-left: 0%;
      margin-bottom: ${theme.size.spacer.large};
    }
  `}
`;

export const SideBySide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const RightSideHeading = styled.div`
  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .medium}px) {
    align-items: flex-start;
  }
`;

export const GrayBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    background-color: ${saux.ColorFillContainerArticle};
    border-radius: 3px;
    margin-top: ${theme.size.spacer.medium};
    padding-top: ${theme.size.spacer.small};
    width: 100%;
    height: 70px;
    @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints.medium}px) {
      padding-top: ${theme.size.spacer.medium};
      padding-left: ${theme.size.spacer.medium};
      height: 75px;
    }
  `}
`;

export const Heading = styled.div`
  margin-bottom: 2px;
`;

export const Spacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-top: ${theme.size.spacer.medium};
  `}
`;

export const BankAccount = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    margin-left: ${theme.size.spacer.medium};
    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      display: none;
    }
  `}
`;

export const AltBankAccount = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    margin-left: ${theme.size.spacer.medium};
    @media screen and (min-width: ${
      customBase.global.breakpoints.medium + 1
    }px) {
      display: none;
    }
  `}
`;
