import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult, FetchResult } from '@apollo/react-hooks';
import ChangeDueDateModal from './ChangeDueDateModal';

const checkChangeDueDateEligibilityMutation = loader(
  './graphql/Check_Update_Bill_Due_Date_Eligibility.graphql',
);

const changeDueDateMutation = loader('./graphql/Update_Bill_Due_Date.graphql');

const getUserIDQuery = loader('./graphql/Get_User_ID.graphql');

interface ChangeDueDateModalDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  setShowModal: Function;
  paymentDueDate: string | number | Date | null | undefined;
  cancelPaymentDisabled: boolean;
  showPastDue: boolean;
  setReRenderComponentAfterChangedDueDate: Function;
}

export const getUserID = (
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<any>> => {
  const queryResult: Promise<ApolloQueryResult<any>> = awsAppSyncClient.query({
    query: getUserIDQuery,
  }) as Promise<ApolloQueryResult<any>>;
  return queryResult;
};

export const changeDueDate = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  updateToNewDueDate: number,
  userID: string,
) => {
  const variables = {
    account_number: accountNumber,
    day: updateToNewDueDate,
    sourceSystem: 'Customer360',
    userID,
  };

  return awsAppSyncClient.mutate({
    mutation: changeDueDateMutation,
    variables,
  }) as Promise<FetchResult<any>>;
};

export const checkChangeDueDate = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
) => {
  const variables = {
    account_number: accountNumber,
  };

  return awsAppSyncClient.mutate({
    mutation: checkChangeDueDateEligibilityMutation,
    variables,
  }) as Promise<FetchResult<any>>;
};

const ChangeDueDateModalDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  setShowModal,
  paymentDueDate,
  cancelPaymentDisabled,
  showPastDue,
  setReRenderComponentAfterChangedDueDate,
}: ChangeDueDateModalDataContainerProps) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [alreadyChanged, setAlreadyChanged] = useState<boolean>(false);
  const [noUpcomingInvoices, setNoUpcomingInvoices] = useState<boolean>(false);
  const [currentDueDate, setCurrentDueDate] = useState<string>('');
  const [mutationFailed, setMutationFailed] = useState<boolean>(false);
  const [mutationSucceeded, setMutationSucceeded] = useState<boolean>(false);
  const [finishedCheckCall, setFinishedCheckCall] = useState<boolean>(false);

  const setUpdateToNewDueDate: Function = (updateToNewDueDate: number) => {
    setLoading(true);
    if (updateToNewDueDate > 0 && updateToNewDueDate < 32) {
      getUserID(awsAppSyncClient)
        .then((resData) => {
          const userid: string = resData.data?.me?.user_id ?? '';

          changeDueDate(
            awsAppSyncClient,
            accountNumber,
            updateToNewDueDate,
            userid,
          ).then((res) => {
            if (res?.data?.updateInvoiceDueDate?.updated) {
              setMutationSucceeded(true);
              setLoading(false);
              setReRenderComponentAfterChangedDueDate(true);
            } else {
              setMutationFailed(true);
              setLoading(false);
            }
          });
        })
        .catch((err) => {
          console.error('GET_USER_ID QUERY ERROR', err);
        });
    }
  };

  useEffect(() => {
    checkChangeDueDate(awsAppSyncClient, accountNumber)
      .then((res) => {
        if (
          res?.data?.updateBillDueDate?.reason &&
          res?.data?.updateBillDueDate?.reason ===
            'Account has no planned invoices and cannot change the due date'
        ) {
          setNoUpcomingInvoices(true);
          setLoading(false);
        } else if (
          res?.data?.updateBillDueDate?.reason &&
          res?.data?.updateBillDueDate?.reason ===
            'Billing due dates can only change once every 365 days'
        ) {
          setAlreadyChanged(true);
          setLoading(false);
        }
        setFinishedCheckCall(true);
      })
      .catch((err) => {
        console.error('CHECK CHANGE DUE DATE CALL ERROR');
        setError(err);
      });
    if (finishedCheckCall && typeof paymentDueDate === 'string') {
      const parsedDate = Date.parse(paymentDueDate);
      setCurrentDueDate(new Date(parsedDate).getDate().toString());
      setLoading(false);
    }
  }, [accountNumber, finishedCheckCall]);

  return (
    <ChangeDueDateModal
      loading={loading}
      error={error}
      setShowModal={setShowModal}
      currentDueDate={currentDueDate}
      setUpdateToNewDueDate={setUpdateToNewDueDate}
      noUpcomingInvoices={noUpcomingInvoices}
      alreadyChanged={alreadyChanged}
      mutationFailed={mutationFailed}
      mutationSucceeded={mutationSucceeded}
      showPastDue={showPastDue}
      cancelPaymentDisabled={cancelPaymentDisabled}
    />
  );
};

export default ChangeDueDateModalDataContainer;
