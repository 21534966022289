import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  overflow: scroll;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  margin: 0px;
  padding: 0px;
  background-color: rgb(4, 30, 65, 74%);
`;

interface ModalProps {
  children: JSX.Element;
}

const JSXModal = ({ children }: ModalProps) => {
  return (
    <ModalWrapper
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event?.stopPropagation();
      }}
      onScroll={(event: any) => {
        event?.preventDefault();
        event?.stopPropagation();
      }}
    >
      {children}
    </ModalWrapper>
  );
};

const Modal = ({ children }: ModalProps) => {
  return ReactDOM.createPortal(
    <JSXModal>{children}</JSXModal>,
    document?.querySelector('#modal') as Element,
  );
};

export default Modal;
