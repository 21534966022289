import { SAText } from '@saux/design-system-react';
import React, { MutableRefObject } from 'react';
import { ReactComponent as InfoIconWhiteOut } from '../../assets/images/InfoIconWhiteOut.svg';
import { ReactComponent as InfoQuestionMark } from '../../assets/images/InfoQuestionMark.svg';
import {
  DesktopToolTipWrapper,
  FullSizeToolTip,
  InfoIconWhiteOutHolder,
  ToolTipHolder,
} from '../nextPayment/NextPaymentStyles';

export interface ToolTip {
  title: string;
  subtitle: string;
}

interface DesktopTooltipProps {
  toolTip: MutableRefObject<ToolTip>;
  setDesktopToolTip: Function;
  desktopToolTip: boolean;
}

const DesktopTooltip = ({
  toolTip,
  setDesktopToolTip,
  desktopToolTip,
}: DesktopTooltipProps) => {
  return (
    <DesktopToolTipWrapper
      test-attr="desktop-tooltip-wrapper"
      onMouseOver={() => {
        setDesktopToolTip(true);
        const siblingSACard: any = document.querySelector(
          '[data-test-id=next-payment-card-overview-page]',
        );
        if (siblingSACard) {
          siblingSACard.style.overflow = 'visible';
        }
      }}
      onMouseLeave={() => {
        setDesktopToolTip(false);
        const siblingSACard: any = document.querySelector(
          '[data-test-id=next-payment-card-overview-page]',
        );
        if (siblingSACard) {
          siblingSACard.style.overflow = 'hidden';
        }
      }}
    >
      <FullSizeToolTip>
        <InfoQuestionMark />
      </FullSizeToolTip>
      {desktopToolTip && (
        <ToolTipHolder>
          <InfoIconWhiteOutHolder>
            <InfoIconWhiteOut />
          </InfoIconWhiteOutHolder>
          <SAText
            type="standard"
            colorVariant="highlight-foreground"
            text={toolTip.current.subtitle}
          />
        </ToolTipHolder>
      )}
    </DesktopToolTipWrapper>
  );
};

export default DesktopTooltip;
