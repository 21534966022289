import {
  SAButton,
  SAPopover,
  SATable,
  SATableColumn,
  SATag,
  SATagProps,
  SAText,
} from '@saux/design-system-react';
import AWSAppSyncClient from 'aws-appsync';
import React, { useEffect, useState } from 'react';
import {
  BillingHistoryRemainingAmount,
  BillingHistoryTable,
  DesktopBillingHistoryTable,
  Header,
  MobileBillingHistoryTable,
  TabActive,
  TabInactive,
} from './BillingHistoryStyles';
import { BillingHistoryLink } from '../billingHistorySubMenu/BillingHistorySubMenuStyles';
import { formatDate, formatMoney } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useDocumentDownload from '../../util/useDocumentDownload';
import { FetchDocumentProps } from '../../util/useDocumentDownload/useDocumentDownload';
import useIsResponsive from '../../util/useIsResponsive';
import BillingHistorySubMenu from '../billingHistorySubMenu';
import { InvoiceDetailsProps } from '../invoiceDetails/DataContainer';

export interface BillingHistoryProps {
  futureBillingInvoices: Billing[];
  pastBillingInvoices: Billing[];
  loading?: boolean;
  error?: Error;
  accountNumber: string;
  client: AWSAppSyncClient<any>;
}

export interface Billing {
  dueDate: string;
  contents: (string | null)[];
  billed: number;
  paid: number;
  docId?: string | null;
  invoiceNumber: number;
}

function processDueDate(dueDate: string): JSX.Element {
  const formattedDueDate: string | undefined = formatDate(dueDate);
  return (
    <SAText type="standard" text={formattedDueDate} colorVariant="default" />
  );
}

function processContents(contents: (string | null)[]): JSX.Element {
  const pills: JSX.Element[] = contents.map(
    (tag: string | null, index: number) => {
      let variant: SATagProps['variant'] = 'secondary-outline';
      switch (tag) {
        case 'Cancellation':
          variant = 'alert-outline';
          break;
        case 'Invoice':
          variant = 'standard-outline';
          break;
        default:
          break;
      }
      const key: string = `${tag}-${index}`;
      return <SATag key={key} label={tag || ''} variant={variant} />;
    },
  );
  return <>{pills}</>;
}

function processAmountBilled(billed: string): JSX.Element {
  const formattedBilled: string | null = formatMoney(billed) ?? '-';
  return (
    <SAText
      type="standard"
      text={formattedBilled}
      weight="bold"
      colorVariant="default"
    />
  );
}

function processAmountPaid(balance: string[]): JSX.Element {
  const remaining: number = parseFloat(balance[0]) - parseFloat(balance[1]);
  const formattedPaid: string | null =
    formatMoney(parseFloat(balance[0])) ?? '$0';
  const formattedRemaining: string | null = formatMoney(remaining) ?? '-';
  return (
    <>
      <SAText type="standard" text={formattedPaid} colorVariant="default" />
      {remaining > 0 ? (
        <BillingHistoryRemainingAmount>
          <SATag label={formattedRemaining} variant="standard-outline-small" />
        </BillingHistoryRemainingAmount>
      ) : (
        <></>
      )}
    </>
  );
}

function organizeBillingInfo(
  billings: Billing[],
  accountNumber: string,
  client: AWSAppSyncClient<any>,
): any[][] {
  const rowInfo: any[][] = [];
  billings.forEach((billing: Billing) => {
    const balance: string[] = [
      billing.paid.toString(),
      billing.billed.toString(),
    ];
    const invoiceData: InvoiceDetailsProps = {
      invoiceNum: billing.invoiceNumber,
      dueDate: billing.dueDate,
      contentTags: billing.contents,
      accountNumber,
      appSyncClient: client,
      docId: billing.docId,
    };
    const columnInfo: any[] = [
      billing.dueDate,
      billing.contents,
      billing.billed,
      balance,
      invoiceData,
    ];
    rowInfo.push(columnInfo);
  });
  return rowInfo;
}

const BillingHistory = ({
  pastBillingInvoices,
  futureBillingInvoices,
  loading,
  error,
  accountNumber,
  client,
}: BillingHistoryProps) => {
  if (error) {
    return <SAText type="standard" text="Error" colorVariant="default" />;
  }
  if (loading) {
    return <SAText type="standard" text="Loading..." colorVariant="default" />;
  }

  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null,
  );
  const [rowClicked, setRowClicked] = useState<number>(-1);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isResponsive = useIsResponsive();
  const tabs: string[] = ['Future Invoices', 'Past Invoices'];

  useEffect(() => {
    if (futureBillingInvoices.length < 1 && pastBillingInvoices.length > 0) {
      setActiveIndex(1);
    }
  }, [futureBillingInvoices, pastBillingInvoices]);

  const processViewDetails = () => (
    <BillingHistoryLink>
      <SAButton
        test-attr="billing-history-view-details-button"
        label="VIEW DETAILS"
        onClick={() => {
          setDisplayModal(true);
          document.body.style.overflow = 'hidden';
        }}
        variant="link"
      />
    </BillingHistoryLink>
  );

  let columns: SATableColumn[] = [
    {
      align: 'left',
      name: 'Due Date',
      process: processDueDate,
    },
    {
      align: 'left',
      name: 'Contents',
      process: processContents,
    },
    {
      align: 'left',
      name: 'Amount Billed',
      process: processAmountBilled,
    },
    {
      align: 'left',
      name: 'Amount Paid',
      process: processAmountPaid,
    },
  ];

  if (isResponsive) {
    columns = [
      {
        align: 'left',
        name: 'Due Date',
        process: processDueDate,
      },
      {
        align: 'left',
        name: 'Contents',
        process: processContents,
      },
      {
        align: 'left',
        name: 'Amount Billed',
        process: processAmountBilled,
      },
      {
        align: 'left',
        name: 'Amount Paid',
        process: processAmountPaid,
      },
      {
        align: 'right',
        name: '',
        process: processViewDetails,
      },
    ];
  }

  const billingHistoryData = () => {
    switch (activeIndex) {
      case 1:
        return organizeBillingInfo(pastBillingInvoices, accountNumber, client);
      default:
        return organizeBillingInfo(
          futureBillingInvoices,
          accountNumber,
          client,
        );
    }
  };

  const onRowClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedRowIndex: number,
  ) => {
    setRowClicked(selectedRowIndex);
  };

  let invoiceNum: any;
  let dueDate = '';
  let contentTags = [];
  let appSyncClient;
  let docId = '';

  if (rowClicked !== undefined && rowClicked >= 0) {
    invoiceNum =
      billingHistoryData()[rowClicked] &&
      billingHistoryData()[rowClicked][4].invoiceNum;
    dueDate =
      billingHistoryData()[rowClicked] &&
      billingHistoryData()[rowClicked][4].dueDate;
    contentTags =
      billingHistoryData()[rowClicked] &&
      billingHistoryData()[rowClicked][4].contentTags;
    appSyncClient =
      billingHistoryData()[rowClicked] &&
      billingHistoryData()[rowClicked][4].appSyncClient;
    docId =
      billingHistoryData()[rowClicked] &&
      billingHistoryData()[rowClicked][4].docId;
  }

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    appSyncClient,
    accountNumber,
  );
  const popoverOptions = ['View Invoice Breakdown'];

  if (docId) {
    popoverOptions.push('Download Invoice');
  }

  const handlePopoverMenuClose = (
    event: React.MouseEvent<HTMLElement> | MouseEvent,
    index: number | null,
  ) => {
    setReferenceElement(null); // required by design system

    const option = index ?? -1;

    switch (popoverOptions[option]) {
      case 'Download Invoice':
        simpleGTMDataLayer({
          event: 'BillingInvoice-BillingHistoryDownloadInvoiceButton-click',
          event_action:
            'Billing Payments Billing History Download Invoice Button Click',
          event_category:
            'Billing Payments Billing History Download Invoice Button',
          event_label:
            'Billing Payments Billing History Download Invoice Button Click',
        });

        simpleGTMDataLayer({
          event:
            'Snowplow-BillingPayments-BillingHistoryDownloadInvoiceButton-click',
          event_action:
            'Snowplow Billing Payments Billing History Download Invoice Button Click',
          event_category:
            'Snowplow Billing Payments Billing History Download Invoice Button',
          event_label:
            'Snowplow Billing Payments Billing History Download Invoice Button Click',
        });

        onDocumentClick({ invoice_number: invoiceNum } as FetchDocumentProps);
        break;

      case 'View Invoice Breakdown':
        simpleGTMDataLayer({
          event: 'BillingPayments-BillingHistoryActionMenuIcon-click',
          event_action:
            'Billing Payments Billing History Action Menu Icon Click',
          event_category: 'Billing Payments Billing History Action Menu Icon',
          event_label:
            'Billing Payments Billing History Action Menu Icon Click',
        });

        simpleGTMDataLayer({
          event: 'Snowplow-BillingPayments-BillingHistoryActionMenuIcon-click',
          event_action:
            'Snowplow Billing Payments Billing History Action Menu Icon Click',
          event_category:
            'Snowplow Billing Payments Billing History Action Menu Icon',
          event_label:
            'Snowplow Billing Payments Billing History Action Menu Icon Click',
        });
        setDisplayModal(true);
        break;
      default:
        break;
    }
  };

  const onActionMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number,
  ) => {
    event.preventDefault();
    setRowClicked(rowIndex);
    setReferenceElement(event.currentTarget);
  };

  function onTabBarChange(newActiveIndex: number) {
    setActiveIndex(newActiveIndex);
  }

  const renderDesktopContent = (returnIndex: number) => {
    switch (returnIndex) {
      case 1:
        return (
          <>
            {billingHistoryData().length < 1 ? (
              <SAText
                type="standard"
                text="No past invoices available"
                colorVariant="default"
              />
            ) : (
              <SATable
                columns={columns}
                data={billingHistoryData()}
                variant="table-to-listview"
                onRowClick={onRowClick}
                onActionMenuClick={onActionMenuClick}
              />
            )}
          </>
        );
      default:
        return (
          <>
            {billingHistoryData().length < 1 ? (
              <SAText
                type="standard"
                text="No future invoices available"
                colorVariant="default"
              />
            ) : (
              <SATable
                columns={columns}
                data={billingHistoryData()}
                variant="table-to-listview"
                onRowClick={onRowClick}
                onActionMenuClick={onActionMenuClick}
              />
            )}
          </>
        );
    }
  };

  const renderMobileContent = (returnIndex: number) => {
    switch (returnIndex) {
      case 1:
        return (
          <>
            {billingHistoryData().length < 1 ? (
              <SAText
                type="standard"
                text="No past invoices available"
                colorVariant="default"
              />
            ) : (
              <SATable
                columns={columns}
                data={billingHistoryData()}
                variant="table-to-listview"
                onRowClick={onRowClick}
              />
            )}
          </>
        );
      default:
        return (
          <>
            {billingHistoryData().length < 1 ? (
              <SAText
                type="standard"
                text="No future invoices available"
                colorVariant="default"
              />
            ) : (
              <SATable
                columns={columns}
                data={billingHistoryData()}
                variant="table-to-listview"
                onRowClick={onRowClick}
              />
            )}
          </>
        );
    }
  };

  return (
    <BillingHistoryTable className="billing-history-table">
      <Header>
        {tabs?.map((tab, index) => {
          const key = `non-ds-tab-${index}`;
          if (index === activeIndex) {
            return (
              <TabActive className={key} key={key}>
                {tab}
              </TabActive>
            );
          }
          if (onTabBarChange) {
            return (
              <TabInactive
                className={key}
                key={key}
                onClick={() => onTabBarChange(index)}
              >
                {tab}
              </TabInactive>
            );
          }
          return (
            <TabInactive className={key} key={key}>
              {tab}
            </TabInactive>
          );
        })}
      </Header>

      <DesktopBillingHistoryTable>
        {renderDesktopContent(activeIndex)}
      </DesktopBillingHistoryTable>
      <MobileBillingHistoryTable>
        {renderMobileContent(activeIndex)}
      </MobileBillingHistoryTable>
      <SAPopover
        data={popoverOptions}
        offset={[-10, 0]}
        onClose={handlePopoverMenuClose}
        placement="bottom-start"
        referenceElement={referenceElement}
        variant="popover-menu-adjss"
      />
      <BillingHistorySubMenu
        invoiceNum={invoiceNum}
        dueDate={dueDate}
        contentTags={contentTags}
        accountNumber={accountNumber}
        appSyncClient={appSyncClient}
        docId={docId}
        closeModalHandler={setDisplayModal}
        displayModal={displayModal}
        onDocumentClick={onDocumentClick}
        documentsModal={documentsModal}
      />
    </BillingHistoryTable>
  );
};

BillingHistory.defaultProps = {
  loading: false,
  error: undefined,
};

export default BillingHistory;
